import {create} from 'apisauce';
import {getCookies, setCookies} from '../store/useCookies';
import axios from 'axios';
//import { useStore } from "../helpers/use-store";
//const { user } = useStore();
import {API_URL} from '../env';

export const MODE_API = {
  UAT: API_URL,
  PRODUCTION: API_URL,
};

export const CONNECT_API = MODE_API.UAT;

export const CONNECT_API_EXCEL = `${API_URL}/static/template/`;

const apis = axios.create({
  baseURL: CONNECT_API,
  //baseURL: "https://bdac-2405-9800-b641-164f-c0e9-a096-d21-ac7c.ap.ngrok.io",

  // withCredentials: true,
  // headers: {
  //   'Content-Type': 'application/json',
  // },
  timeout: 300000, // 10 seconds
});

const api = create({axiosInstance: apis});

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'multipart/form-data',
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

export const POST_FORM_DATA = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const DELETE = (path) =>
  new Promise((resolve, reject) => {
    api
      .delete(
        path,
        {},
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json; charset=utf-8',
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const PUT = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */

export const UPLOAD = (path, formdata) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ เพิ่มข้อมูล
 * @param {string} path
 *
 */

export const PATCH = (path, obj) =>
  new Promise((resolve, reject) => {
    api
      .patch(path, obj, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data ? reject(response.data) : reject({success: false, message: response.problem});
        }
      })
      .catch((err) => reject(err));
  });
/* ################################################## URL ################################################## */
export const GET_ALL_USER_ADMIN = `/admin/authen/getAllUserAdmin`;
export const GET_OPTION_MENU = `/admin/authen/getOptionMenu`;
export const CREATE_USER_ADMIN = `/admin/authen/createUserAdmin`;
export const GET_DETAIL_USER_ADMIN = (id) => `/admin/authen/getDetailUserAdmin?_id=${id}`;
export const EDIT_USER_ADMIN = `/admin/authen/editUserAdmin`;
export const GET_ACCOUNT_RANDOM = `/admin/account/initRandom`;
export const GET_OPTION_EMAIL = `/admin/account/email/options`;
export const GET_ACCOUNT_MAIL = (page, size) => `/admin/account/email?page=${page}&size=${size}`;
export const GET_CHECK_MAIL_STATUS_ICLOUD = () => `/admin/auto-check-icloud/checkStatusMailIcloud`;
export const GET_CHANGE_MAIL_STATUS_ICLOUD = () => `/admin/auto-check-icloud/changeStatusMailIcloud`;
export const RANDOM_TEL = `/admin/account/randomTel`;
export const CREATE_ACCOUNT = `/admin/account/create`;
export const EDIT_ACCOUNT = `/admin/account/update`;
export const MANAGE_ACCOUNT = `/admin/account/manageICloud`;
export const RANDOM_KEY_PASS = `/admin/account/randomKeyPass`;
export const GET_TELEPHONE = `/admin/telephone`;
export const CREATE_TELEPHONE = `/admin/telephone/create`;
export const EDIT_TELEPHONE = `/admin/telephone/update`;
export const GET_ACCOUNT_WITH_CONTRACT = `/admin/account/accountWithContract`;
export const RAMDOM_ACCOUNT_ICLOUD = `/admin/account/accountWithContract/update`;
export const RAMDOM_MAIL = `/admin/account/randomMail`;
export const GET_OPTION_BRANCH = `/admin/authen/branchAdmin/options`;
export const UPDATE_STATUS_WAIT_REGISTER = `/admin/account/update-status-wait-register`;

export const GET_TRACKINGNO = `/admin/notice-of-termination-of-contract`;
export const GET_SENDIND_STATUS = `/admin/notice-of-termination-of-contract/enum/sending-status-list`;
export const GET_CONTRACT_STATUS = `/admin/notice-of-termination-of-contract/enum/contract-status-list`;
export const GET_TRACKING_STATUS = `/admin/notice-of-termination-of-contract/enum/tracking-status-list`;
export const GET_JOBTYPE = `/admin/notice-of-termination-of-contract/enum/jobtype-status-list`;
export const GET_TERMINATED_CONTRACT_OPTION = `/admin/notice-of-termination-of-contract/enum/type-status-list`;
export const GET_TRACKING_TASK_SUMMARY = `/admin/notice-of-termination-of-contract/summary`;
export const ADD_TRACKING_NO = `/admin/notice-of-termination-of-contract`;
export const GET_TRACKING_TASK = `/admin/tracking-task`;
export const GET_TRACKER_BY_TERMINATE_ID = `/admin/tracking-task/getTrackerFromTerminateId`;
export const GET_SORT_BY_OPTION = `/admin/tracking-task/enum/sortby-list`;

export const GET_TRACKERS = `/admin/tracking-task/trackers`;
export const APPROVE_CONTACT_ONLINE = `/admin/contract/updateStatusContractOnline`;
export const GET_CONTRACT_REFINANCE_WAIT_APPROVE = (contractNo) => `/admin/contract/refinance/waitingApprove?contractNo=${contractNo}`;
// export const GET_DATA_CONSIDER = (startDate,status,endDate,type)=>`/admin/consider/search?startDate=${startDate}&status=${status}&endDate=${endDate}&type=${type}`
export const GET_SUMMARY_CONSIDER = `/admin/consider/summary-report`;
export const GET_CONSIDER_STATUS = `/app/option/consider/status`;
export const GET_PRODUCT_MODEL = (type, category,pdHand) => `/app/option/product-model?type=${type}&category=${category}&productHand=${pdHand}`;
// export const GET_PRODUCT_MODEL = (type,) => `/app/option/product-model?type=${type}`;
export const GET_PRODUCT_STORAGE = (type, model,pdHand) => `/app/option/product-storage?type=${type}&model=${model}&productHand=${pdHand}`;
export const GET_PRODUCT_COLOR = (type, model, storage,pdHand) => `/app/option/product-color?type=${type}&model=${model}&storage=${storage}&productHand=${pdHand}`;
export const GET_PRODUCT_COMBO = `/app/option/product-combo`;
export const CONSIDER_RECEIVE = (registerId) => `/admin/consider/receive?registerId=${registerId}`;
export const GET_CONSIDER_DETAIL = (registerId) => `/admin/consider/detail?registerId=${registerId}`;
export const INSTALLMANT_CALCULATE = `/app/installment/calculate`;
export const GET_ADDRESS = `/app/option/address`;
export const GET_BUSINESS = `/app/option/business`;
export const GET_JOB = `/app/option/job`;
export const UPLOAD_FILE = `/app/authen/register-upload`;
export const GET_DATA_CALENDAR = (startDate, endDate) => `/admin/consider/calendar?startDate=${startDate}&endDate=${endDate}`;
export const GET_BRANCH = `/app/option/branch`;
export const GET_BRANCH_USER = `/admin/authen/branchUser/options`;
export const GET_BRANCH_REGISTER = `/admin/authen/Branch_Web_Register/options`;
export const CREATE_UPDATE_APPOINTMENT = (registerId, considerId) =>
  `/admin/consider/appointment/upsert?registerId=${registerId}&considerId=${considerId}`;
export const DELETE_APPOINTMENT = (registerId, considerId) => `/admin/consider/calendar?registerId=${registerId}&considerId=${considerId}`;
export const EDIT_CONSIDER = (registerId) => `/admin/consider/edit?registerId=${registerId}`;
export const CONFIRM_CONSIDER = (registerId, considerId) => `/admin/consider/confirm?registerId=${registerId}&considerId=${considerId}`;
export const GET_CONSIDER_DETAIL_BY_ID = (registerId, considerId) =>
  `/admin/consider/calendar/detail?considerId=${considerId}&registerId=${registerId}`;
export const REPLACE_RECEIVE = `/admin/consider/replace-receive`;
export const EDIT_REMARK = (registerId) => `/admin/consider/edit-remark?registerId=${registerId}`;
export const CREATE_FOLLOWUP = (registerId, considerId) => `/admin/consider/followup/upsert?registerId=${registerId}&considerId=${considerId}`;
export const DELETE_CONSIDER = `/admin/consider/delete`;
export const RE_RECIEVE = `/admin/consider/re-receive`;
export const CALCULATE_A_SCORE = (registerId) => `/admin/consider/calculate/ascore?registerId=${registerId}`;
export const INSTALLMENT_REFINANCE = (amount) => `/admin/consider/calculate/refinance?amount=${amount}`;
export const CHECK_CUSTOMER = (idCard) => `/admin/customer/register/check?idCard=${idCard}`;
export const GET_LOOKUP_REFINANCE = `/admin/contract/refinance/lookUpWithdrawRefinance`;
export const GET_CONFIRM_REFINANCE = `/admin/contract/refinance/confirmWithdrawRefinance`;
export const CANCEL_LOOKUP = `/admin/contract/refinance/lookUpWithdrawRefinance/cancel `;
export const GET_STOCK_AND_RESERVATION = `/admin/stock/get-stock-and-reservation`;
export const INSERT_JOURNAL_ITEM = `/admin/stock/insert-journal-items`;
export const GET_JOURNAL_ITEM = `/admin/stock/get-journal-items`;
export const GET_ITEM_BY_SN = `/admin/stock/get-item`;
export const UPDATE_JOURNAL_ITEMS = `/admin/stock/update-journal-items`;
export const CONFIRM_DOC = `/admin/stock/confirm-document`;
export const VIEW_PICK_DOC = `/admin/stock/view-document`;
export const GET_JOURNAL_HEADER = (docNo) => `/admin/stock/get-journal-header?documentNo=${docNo}`;

export const RECHECK_ITEMS = `/admin/stock/check-items`;
export const CONFIRM_RECIEVE_ITEMS = `/admin/stock/confirm-receive-items`;
export const GET_OPTION_BY_KEY = (key) => `/admin/stock/get-stock-options?key=${key}`;
export const GET_PICK_REPORT = (doc, status, productHand) => `/admin/stock/get-pick-report?doc=${doc}&status=${status}&productHand=${productHand}`;
export const GET_STOCK_LIST = (docNo) => `/admin/stock/get-stock-list?documentNo=${docNo}`;
export const CHECK_STOCK_ITEMS = `/admin/stock/check-stock-items`;
export const CONFIRM_STOCK = `/admin/stock/confirm-stock`;
export const GET_MONITOR_STOCK_REPORT = (branch, product, type, serial_status) =>
  `/admin/stock/get-stock-report?branch=${branch}&product=${product}&type=${type}&serial_status=${serial_status}`;
export const GET_PRODUCTS_STOCK = `/admin/stock/get-product-items`;
export const GET_PRODUCTS = `/admin/stock/get-product`;
export const GET_SERIAL_BY_ITEM_CODE = (code) => `/admin/stock/get-serial-by-item?code=${code}`;
export const GET_NO_ADJUST = `/admin/stock/get-document-no-adjust`;
export const INSERT_ADJUST = `/admin/stock/insert-adjust-stock`;
// export const GET_CONSIDER_DATA = (
//   page,
//   size,
//   fullName,
//   tel,
//   appointmentStartDate,
//   appointmentEndDate,
//   registerStartDate,
//   registerEndDate,
//   status,
//   type,
//   branch,
//   walkIn
// ) =>
//   `/admin/consider/search?page=${page}&size=${size}&fullName=${fullName}&tel=${tel}&appointmentStartDate=${appointmentStartDate}&appointmentEndDate=${appointmentEndDate}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&status=${status}&type=${type}&branch=${branch}&walkIn=${walkIn}`;
export const GET_CONSIDER_DATA = (
  page,
  size,
  fullName,
  tel,
  appointmentStartDate,
  appointmentEndDate,
  registerStartDate,
  registerEndDate,
  status,
  type,
  branch,
  walkIn,
  productHand,
  remark,
) =>
  `/admin/consider/search?page=${page}&size=${size}&fullName=${fullName}&tel=${tel}&appointmentStartDate=${appointmentStartDate}&appointmentEndDate=${appointmentEndDate}&registerStartDate=${registerStartDate}&registerEndDate=${registerEndDate}&status=${status}&type=${type}&branch=${branch}&walkIn=${walkIn}&productHand=${productHand}&remark=${remark}`;
export const EDIT_MAIN_SOCIAL = (registerId) => `/admin/consider/edit-main-social?registerId=${registerId}`;
export const GET_ADJUST_STOCK_REPORT = (name, serial, dateStart, dateEnd, serial_status, document_no) =>
  `/admin/stock/get-adjust-stock-report?name=${name}&serial=${serial}&dateStart=${dateStart}&dateEnd=${dateEnd}&serial_status=${serial_status}&document_no=${document_no}`;
export const GET_UNLOCK_ICLOUD_CONTRACT = (approve_status, user_name, user_admin_name, page, size) =>
  `/admin/unlock-icloud/admin-account?approve_status=${approve_status}&user_name=${user_name}&user_admin_name=${user_admin_name}&page=${page}&size=${size}`;
export const GET_ICLOUD_PASSWORD_BY_CONTRACT = (contractNo) => `/admin/unlock-icloud/${contractNo}/get-icloud-password`;
export const EDIT_UNLOCK_APPROVE_STATUS = (contractNo) => `/admin/unlock-icloud/${contractNo}/approve`;
export const GET_UNLOCK_ICLOUD_CRM = (unlock_status, user_name, page, size) =>
  `/admin/unlock-icloud/admin-crm?unlock_status=${unlock_status}&user_name=${user_name}&page=${page}&size=${size}`;
export const EDIT_UNLOCK_ICLOUD_STATUS_CRM = (contractNo) => `/admin/unlock-icloud/${contractNo}/update-unlock-status`;
export const GET_ICLOUD_TRACKING_STATUS = (params) => `/admin/auto-check-icloud/tracking-icloud?${params}`;
export const GET_ICLOUD_FILTER_OPTION = `/admin/auto-check-icloud/filter-option`;
export const GET_EXPORT_AUTO_CHECK_ICLOUD = (params) => `/admin/auto-check-icloud/export-icloud${params}`;
export const EDIT_REMARK_UNLOCK = (contractNo) => `/admin/unlock-icloud/${contractNo}/update-remark`;
export const GET_BLACKLIST_CUSTOMER = ({name, tel, id_card}) =>
  `/admin/blacklist/customer?name=${name || ''}&tel=${tel || ''}&id_card=${id_card || ''}`;
export const INSERT_BLACKLIST_CUSTOMER = `/admin/blacklist/insertBlacklistCustomer`;
export const GET_BLACKLIST_LINK = `/admin/blacklist/link`;
export const GET_PRODUCTS_PACKAGES = (name) => `/admin/packages/get-product-package?name=${name}`;
export const GET_PRODUCT_PACKAGES_DETAIL = (code) => `/admin/packages/get-product-package/detail?code=${code}`;
export const GET_OPTION_PACKAGE_BY_KEY = (key) => `/admin/packages/options?key=${key}`;
export const UPSERT_PRODUCT_PACKAGE = `/admin/packages/upsert-product-package`;
export const GET_PACKAGE_DETAIL_BY_ID = (id, branch) => `/admin/packages/get-detail-package-by-id?id=${id}&branch=${branch}`;
export const GET_ITEMS_NON_SERIAL = `/admin/stock-items/get-items`;
export const GET_JOURNAL_ITEM_NON_SERIAL = `/admin/stock-items/get-journal-items`;
export const INSERT_JOURNAL_ITEM_NON_SERIAL = `/admin/stock-items/insert-journal-items`;
export const UPDATE_JOURNAL_ITEMS_NON_SERIAL = `/admin/stock-items/update-journal-items`;
export const CONFIRM_DOC_NON_SERIAL = `/admin/stock-items/confirm-document`;
export const RECHECK_ITEMS_NON_SERIAL = `/admin/stock-items/check-items`;
export const CONFIRM_RECIEVE_ITEMS_NON_SERIAL = `/admin/stock-items/confirm-receive-items`;
export const GET_NO_ADJUST_NON_SERIAL = `/admin/stock-items/get-document-no-adjust`;
export const GET_ITEMS_NON_SERIAL_INFO = (code, branch) => `/admin/stock-items/get-item-info?code=${code}&branch=${branch}`;
export const INSERT_ADJUST_NON_SERIAL = `/admin/stock-items/insert-adjust-stock`;
export const GET_MONITOR_STOCK_NON_SERIAL_REPORT = (branch, product) => `/admin/stock-items/get-stock-report?branch=${branch}&product=${product}`;
export const GET_PICK_NON_SERIAL_REPORT = (doc, status) => `/admin/stock-items/get-pick-report?doc=${doc}&status=${status}`;
export const GET_ADJUST_STOCK_NON_SERIAL_REPORT = (name, dateStart, dateEnd) =>
  `/admin/stock-items/get-adjust-stock-report?name=${name}&dateStart=${dateStart}&dateEnd=${dateEnd}`;
export const SUMMARY_TRACK = `/admin/track/summary`;
export const GET_TRACK_DAILY_REPORT = `/admin/dailyreport`;
export const GET_TRACK_DAILY_DETAIL = `/admin/dailyreportdetail`;

export const GET_DIFARMASTER = (Code, Telephone, PersonType, IsImport) =>
  `/admin/interface-bc/DIFARMaster?Code=${Code}&Telephone=${Telephone}&PersonType=${PersonType}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFARMASTER = `/admin/interface-bc/reimport-DIFARMaster`;
export const GET_DIFAPMASTER = (Code, Telephone, PersonType, IsImport) =>
  `/admin/interface-bc/DIFAPMaster?Code=${Code}&Telephone=${Telephone}&PersonType=${PersonType}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFAPMASTER = `/admin/interface-bc/reimport-DIFAPMaster`;
export const GET_DIFItemMASTER = (Code, Name1, StockType, IsImport) =>
  `/admin/interface-bc/DIFItemMaster?Code=${Code}&Name1=${Name1}&StockType=${StockType}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFItemMASTER = `/admin/interface-bc/reimport-DIFItemMaster`;
export const GET_DIFINVOICE = (DocNo, DocDate, BillType, ArCode, IsImport) =>
  `/admin/interface-bc/DIFInvoice?DocNo=${DocNo}&DocDate=${DocDate}&BillType=${BillType}&ArCode=${ArCode}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFINVOICE = `/admin/interface-bc/reimport-DIFInvoice`;
export const GET_DIFINVOICE_DETAIL = (DocNo, ItemCode, IsImport) =>
  `/admin/interface-bc/DIFInvoiceDetail?DocNo=${DocNo}&ItemCode=${ItemCode}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFINVOICE_DETAIL = `/admin/interface-bc/reimport-DIFInvoiceDetail`;
export const GET_DIFSERIAL_TRANS = (SerialNo, DocNo, DocDate, SaveFrom, IsImport) =>
  `/admin/interface-bc/DIFSerialTrans?SerialNo=${SerialNo}&DocNo=${DocNo}&DocDate=${DocDate}&SaveFrom=${SaveFrom}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFSERIAL_TRANS = `/admin/interface-bc/reimport-DIFSerialTrans`;
export const GET_DIFRECEIPT = (DocNo, DocDate, IsImport) => `/admin/interface-bc/DIFReceipt?DocNo=${DocNo}&DocDate=${DocDate}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFRECEIPT = `/admin/interface-bc/reimport-DIFReceipt`;
export const GET_DIFRECEIPT_DETAIL = (DocNo, InvoiceNo, BillType, IsImport) =>
  `/admin/interface-bc/DIFReceiptDetail?DocNo=${DocNo}&InvoiceNo=${InvoiceNo}&BillType=${BillType}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFRECEIPT_DETAIL = `/admin/interface-bc/reimport-DIFReceiptDetail`;
export const GET_DIFREC_MONEY = (DocNo, PaymentType, RefDate, IsImport) =>
  `/admin/interface-bc/DIFRecMoney?DocNo=${DocNo}&PaymentType=${PaymentType}&RefDate=${RefDate}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFREC_MONEY = `/admin/interface-bc/reimport-DIFRecMoney`;
export const GET_DIFGPINVOICE = (DocNo, DocDate, BillType, IsImport) =>
  `/admin/interface-bc/DIFGPInvoice?DocNo=${DocNo}&DocDate=${DocDate}&BillType=${BillType}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFGPINVOICE = `/admin/interface-bc/reimport-DIFGPInvoice`;
export const GET_DIFGPINVOICE_DETAIL = (DocNo, ItemCode, IsImport) =>
  `admin/interface-bc/DIFGPInvoiceDetail?DocNo=${DocNo}&ItemCode=${ItemCode}&IsImport=${IsImport}`;
export const EDIT_IMPORT_DIFGPINVOICE_DETAIL = `/admin/interface-bc/reimport-DIFGPInvoiceDetail`;
export const FECTH_UPDATE_PACKAGE = `/admin/packages/fetch-update-package-banchiang`;
export const FECTH_UPDATE_PRODUCT_SERIAL = `/admin/stock/fetch-insert-product-serial`;

export const GET_REPORT_BUY_STOCK = `/admin/stock/get-report-buy-stock`;
export const GET_MANAGE_DEP_DATA = `/admin/stock/get-manage-dep`;
export const GET_MANAGE_DEP_EXPORT = `/admin/stock/get-manage-dep/export`;
export const EDIT_MANAGE_DEP = `/admin/stock/update-dep-status`;
export const GET_REPORT_STOCK_SERIAL = `/admin/stock/get-report-stock-serial`;
export const GET_REPORT_STOCK_BRANCH = `/admin/stock/report-stock-branch`;
export const GET_REPORT_COUNT_STOCK = `/admin/stock/report-count-stock`;
export const GET_PRODUCT_NAME_OPTION = `/admin/product/get-product/product-names`;

export const GET_ALL_CONFIG_ID_MIRADORE = `/admin/mdm/getAllConfigProfile`;
export const ADD_CONFIG_PROFILE_MIRADORE = `/admin/mdm/addConfigProfile`;
export const GET_DEVICE_INFO_MIRADORE = `/admin/mdm/get-device-data`;
export const ACTIVATED_LOST_MODE_MIRADORE = `/admin/mdm/Activate/LostModeAndConfigProfile`;

export const EDIT_CANCEL_CONSIDER = `/admin/consider/cancel-consider`;
export const EDIT_NO_PROMISE_CONSIDER = `/admin/consider/no-promise`;

export const SORT_ICLOUD_TEL = `/admin/account/sort-randomTel`;
export const SORT_MAIL_TEL = `/admin/account/email-randomTel`;

export const EDIT_REMARK_CUSTOMER_INFO = `/admin/customer/create_remark`;
export const GET_CONTRACT_NO_OPTION_MANAGE_ACCOUNT = `/admin/contract/get_data_contractNumber`;
export const GET_CONTRACT_NO_DATA_MANAGE_ACCOUNT = (contract_no) => `/admin/contract/get_data_contractNumber/${contract_no}`;
export const EDIT_CONTRACT_NO_MANAGE_ACCOUNT = (contract_no) => `/admin/contract/update_iCloud/${contract_no}`;

export const DELETE_PICK_DOC = `/admin/stock/delete-journal-items`;
export const DELETE_COUNT_DOC = `/admin/stock/delete-stock-list`;

export const GET_REPORT_TRACKING_FEE = `/admin/tracking-task/tracking-fee`;
export const GET_REPORT_TRACKING_DAILY = `/admin/tracking-task/get_report_daily`;
export const GET_REPORT_TRACKING_AGENT = `/admin/tracking-task/get_one_report_daily`;

export const GET_TRACKERS_ID = `/admin/tracking-task/get_trackers`;
export const GET_INTEREST_RATE_REFINANCE_DATA = `/admin/interest-rate/get-list-interest`;

export const GET_DATA_EXPORT_CONTRACT = (params) => `/admin/contract/export_excel${params}`;
export const GET_DATA_EXPORT_CONSIDER = (params) => `/admin/consider/export_excel${params}`;

export const GET_REPORT_DOWN_PAYMENT_COUNT_HEADER = (params) => `/admin/down-payment/count-header-downpayment-report?${params}`;
export const GET_REPORT_DOWN_PAYMENT = (params) => `/admin/down-payment?${params}`;
export const GET_REPORT_DOWN_PAYMENT_DAIRY = (params) => `/admin/down-payment/get-dairy-downpayment?${params}`;
export const GET_STATEMENT_DOWN_PAYMENT = (params) => `/admin/down-payment/report?${params}`;
export const GET_STATEMENT_DOWN_PAYMENT_DETAIL = (id) => `/admin/down-payment/detail?id=${id}`;
export const GET_DATA_EXPORT_DOWN_PAYMENT = (params) => `/admin/down-payment/export_excel?${params}`;
export const GET_DATA_EXPORT_DOWN_PAYMENT_DOWNPAYMENT = (params) => `/admin/down-payment/export_excel_downpayment?${params}`;
export const GET_DATA_EXPORT_DOWN_PAYMENT_DAIRY = (params) => `/admin/down-payment/export-excel-dairy-downpayment?${params}`;
export const GET_AMOUNT_DOWN_PAYMENT = `/admin/down-payment/get_amount_downpayment`;

export const EDIT_REMARK_DOWN_PAYMENT = (id) => `/admin/down-payment/edit-remark?id=${id}`;
export const EDIT_FACEBOOK_LINEID_DOWN_PAYMENT = `/admin/down-payment/update/line_fb`;
export const GET_PDF_EXPORT_DOWN_PAYMENT = (downPaymentNo) => `/admin/down-payment/${downPaymentNo}/export`;
export const GET_FINANCE_DOWN_PAYMENT = (params) => `/admin/down-payment/finance?${params}`;
export const GET_FINANCE_DOWN_PAYMENT_VERIFY = (id) => `/admin/down-payment/verify?id=${id}`;
export const INSERT_OTHER_IMAGE = `/admin/contract/uploadOtherImage`;
export const NOTI_RE_UPLOAD_DOWN_PAYMENT = `/admin/down-payment/noti_re_upload`;

export const USER_DETAIL_UPDATE = (id) => `/admin/down-payment/user-detail/${id}/update`;
export const GET_DOWN_PAYMENT = `/app/down-payment/get_payment`;
export const CALCULATE_AMOUNT_DOWN_PAYMENT = `/app/installment/calculate/amount/downpayment`;
export const CALCULATE_MONTH_DOWN_PAYMENT = `/app/installment/calculate/month/downpayment`;
export const UPDATE_STATUS_DOWN_PAYMENT = `/admin/down-payment/update-status`;
export const DOWN_PAYMENT_UPDATE_PRODUCT = `/admin/down-payment/update_product`;
export const GET_BANK_OPTION = `/app/contract/getBankOptions`;
export const GET_DATA_OCR = `/admin/contract/get-data-by-ocr`;
export const EDIT_CANCEL_DOWN_PAYMENT = `/admin/down-payment/cancel`;

export const GET_LOOKUP_DOWNPAYMENT = `/admin/contract/refinance/lookUpWithdrawDonwPayMent`;
export const GET_CONFIRM_DOWNPAYMENT = `/admin/contract/refinance/confirmWithdrawDonwPayMent`;

export const POST_SECOND_HAND_VERIFY = (docNo) => `/admin/secondhand/detail?document_no=${docNo}`;
export const POST_SECOND_HAND = (params) => `/admin/secondhand/approve_secondhand?${params}`;
export const EDIT_REMARK_SECOND_HAND = (id) => `/admin/secondhand/edit-remark?id=${id}`;
export const GET_SECOND_HAND_PRODUCT_OPTION = `/admin/secondhand/product_list`;
export const GET_SECOND_HAND_PRODUCT_LIST = (page, size) => `/admin/secondhand?page=${page}&size=${size}`;
export const GET_SECOND_HAND_PRODUCT_EXPORT = `/admin/secondhand/export_excel`;
export const GET_SECOND_HAND_PRICE = `/admin/secondhand/check_max_price`;
export const GET_SECOND_HAND_CONDITION_LIST = `/admin/secondhand/condition_list`;
export const CREATE_NEW_RECORD_SECOND_HAND = `/admin/secondhand/add-list`;
export const POST_SECOND_HAND_DETAIL = (docNo) => `/admin/secondhand/get_data?document_no=${docNo}`;
export const POST_PDF_EXPORT_SECOND_HAND = (documentNumber) => `/admin/secondhand/${documentNumber}/export`;
export const PUT_APPROVE_DOC = (params) => `/admin/secondhand/edit-status?${params}`;
export const CANCEL_SECOND_HAND = `/admin/secondhand/terminated `;
export const CONFIRM_CONTRACT_BY_CUSTOMER_SECOND_HAND = '/admin/secondhand/confirmContractByCustomer';

export const GET_LOOKUP_SECOND_HAND = `/admin/secondhand/lookUpWithdrawSecondhand`;
export const GET_CONFIRM_SECOND_HAND = `/admin/secondhand/confirmWithdrawSecondhand`;
export const GET_DETAIL_EDIT_SECOND_HAND = (docNo) => `/admin/secondhand/get_edit_detail?document_no=${docNo}`;
export const EDIT_RECORD_SECOND_HAND = (docNo) => `/admin/secondhand/edit-list?document_no=${docNo}`;

export const EDIT_SPECIAL_PRODUCT = `/admin/secondhand/edit-manage-secondhand`;
export const GET_SPECIAL_PRODUCT_DATA = (params) => `/admin/secondhand/manage-secondhand?${params}`;

export const GET_SECOND_HAND_PRICE_DATA = (params) => `/admin/secondhand/show-product?${params}`;
export const GET_SECOND_HAND_PRICE_DATA_EXPORT = (params) => `/admin/secondhand/export-excel-edit-secondhand${params}`;
export const CREATE_SECOND_HAND_PRICE = `/admin/secondhand/create`;
export const EDIT_SECOND_HAND_PRICE = `/admin/secondHand/edit-price`;
export const GET_PRODUCT_MODEL_ALL = `/admin/secondHand/product-all`;
export const GET_PRODUCT_STOARGE_ALL = `/admin/secondhand/storage-all`;
export const GET_PRODUCT_COLOR_ALL = `/admin/secondhand/color-all`;
export const GET_PRODUCT_ALL = `/admin/product/get-product/product-name-all`;

export const EDIT_REMARK_STOCK_SERIAL = `/admin/stock/update-remark-serial`;
export const GET_MOVEMENT_REPORT = `/admin/stock/get-movement-report`;
export const GET_MOVEMENT_REPORT_EXPORT = `/admin/stock/get-movement-report/export`;
export const RETRY_AUTO_CHECK_ICLOUD = `/admin/auto-check-icloud/re-check-icloud`;

export const GET_ETC_DATA_INFO = (customerId) => `/admin/customer/get-etc-data/${customerId}`;
export const ADD_ETC_DATA_INFO = `/admin/customer/add-etc-data/`;
export const UPLOAD_IMAGE_CUSTOMER_INFO = `/admin/customer/customer-upload`;

export const GET_NAME_BY_CONTRACT = `admin/tracking-task/show-contract-detail`;
export const UPLOAD_TRACKING = `/admin/tracking-task/uploadTracking`;

export const GET_INFO_FROM_TRACKING = `/admin/tracking-task/get-info-from-tracking`;
export const UPDATE_TRACKING_ACKNOWLEDGMENT = `/admin/tracking-task/add-acknowledgment-date`;
export const GET_TERMINATED_CONTRACT = `admin/tracking-task/get-terminated-contract`;

export const GET_TRACKING_ACKNOWLEDGMENT = `/admin/tracking-task/tracking-report`;
export const GET_MISTAKE_TERMINATE_CONTRACT = `/admin/tracking-task/get-mistake-terminated-contract`;
export const GET_FIND_INFO_CONTRACT = `/admin/contract/find-detail-from-contract`;

export const FIND_NAME_BY_CONTRACT = `/admin/tracking-task/show-contract-detail`;
export const EXPORT_TERMINATE_CONTRACT = `/admin/contract/export-terminate-contract-pdf`;
export const EXPORT_ACKNOWLEDGMENT_RECEIPT = `/admin/contract/export-acknowledgment-receipt-pdf`;
export const ADD_SPECIAL_TASK = `/admin/tracking-task/addSpecialTrack`;
export const GET_HISTORY_LOCATION = `/admin/tracking-task/check-location-history`;

export const EXPORT_MISTAKE_TERMINATED = `/admin/contract/export-mistake-terminated-pdf`;
export const GET_RETURN_STORE = `/admin/tracking-task/get-return-store-list`;
export const CHANGE_REDEEM_DATE = `/admin/tracking-task/change-redeem-date`;
export const EDIT_TRACKING_REMARK = `/admin/tracking-task/edit-remark`;
export const ACCEPT_REDEEM_DEVICE = `/admin/tracking-task/accept-redeem-phone`;
export const GET_DETAIL_RECEIVE_DEVICE = `/admin/tracking-task/check-receive-phone`;
export const ADD_CHECK_RECEIVE_DEVICE = `/admin/tracking-task/add-check-receive-phone`;
export const CREATE_OTHER_FEE = `/admin/tracking-task/create-other-fee`;
export const GET_TRACK_TASK = `/admin/tracking-task/manage-tracking-task`;
export const CHANGE_TRACKING_FEE = `/admin/tracking-task/change-tracker`;
export const GEN_QR_CODE_BBL = `/admin/payment/get_qrcode_bbl`;

export const GET_RESERVE_REPORT = `/admin/stock/get_reserve`;
export const GET_RESERVE_REPORT_EXPORT = `/admin/stock/get_reserve/export`;

export const UPLOAD_PROFILE_IMAGE = (params) => `/admin/customer/upload-profile-image/${encodeURIComponent(params)}`;

export const GET_BRANCH_OPTION_SPECIFIC = `/admin/authen/branchDashboard/options`;
export const EXPORT_MISTAKE_PDF = `/admin/contract/export-mistake-terminated-pdf`;
export const GET_BRANCH_CALENDAR_OPTION = `/admin/authen/branchCalendar/options`;

/* FGF: 3.อนุมัติคอมมิชชั่น API */
export const POST_FGF_APPROVE_COMMISSION_LIST = (params) => `admin/friend-get-friend/getFGFTransactionCommissionReport?${params}`;
export const POST_FGF_APPROVE_COMMISSION_VERIFY = (id) =>
  `/admin/friend-get-friend/getFGFTransactionCommissionDetail?transaction_commission_no=${id}`;
export const POST_FGF_CONFIRM_APPROVE_COMMISSION = `/admin/friend-get-friend/confirmWithdrawFGF`;
export const GET_FGF_LOOKUP_COMMISSION = `/admin/friend-get-friend/lookUpWithdrawFGF`;

export const GET_REGISTER_BRANCH_NEW = `/app/option/register-branch`;
export const GET_REGISTER_SALARY = `/app/option/register-salary`;

// GET PRODUCT DOWNPAYMENT
export const GET_PRODUCT_MODEL_DOWNPAYMENT = (type) => `/app/option/product-model-downpayment?type=${type}`;
export const GET_PRODUCT_STORAGE_DOWNPAYMENT = (type, model) => `/app/option/product-storage-downpayment?type=${type}&product_master_code=${model}`;
export const GET_PRODUCT_COLOR_DOWNPAYMENT = (type, model, storage) =>
  `/app/option/product-color-downpayment?type=${type}&product_master_code=${model}&storage_code=${storage}`;

export const GET_CHECK_ICLOUD_BEFORE_APPROVE = (mail) => `/admin/contract/check-icloud-before-approve?mail=${mail}`;
export const GET_CONTRACT_REMARK = (params) => `/admin/contract/get-contract-remark?${params}`;
export const PUT_EDIT_REMARK = `/admin/contract/edit-remark`;
export const GET_PRODUCT_COMBO_DOWNPAYMENT = `/app/option/product-combo/aomdown`;
