import React, { createRef, useState } from 'react';
import {
  CalendarOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  EditOutlined,
  EyeOutlined,
  FileDoneOutlined,
  SearchOutlined,
  SettingOutlined,
  StopOutlined,
  SwapOutlined,
  BellOutlined,
} from '@ant-design/icons';
import { Button, Tag, Tooltip, Space, Row, Table, Modal, Select, Typography, Input, DatePicker } from 'antd';
import { color } from '../../resources/color';
import { renderSearchColumn } from './renderSearchColumn';
import moment from 'moment';
import { TextSmall } from '../../components/forms/text';
import { convertStrToFormat, statusTagStyle } from '../../functions/fn';
import { CONTRACT_STATUS, convertStatusENtoTH, SENDING_STATUS, TRACKING_STATUS } from '../status/status';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Icon } from '../../resources/icon';
import { ButtonTheme } from '../../components/buttons';
import { ContainerButton } from '../../styles/styledComponentGlobal';
import { FileOutlined } from '@ant-design/icons';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem } from '@material-ui/core';

import { Edit, CheckCircle, Cancel } from '@material-ui/icons';

const { Text, Title, Paragraph } = Typography;

const useStyles = makeStyles({
  select: {
    '& > div:focus': {
      background: 'none',
    },
  },
  icon_btn: {
    cursor: 'pointer',
    '&.save': {
      color: '#01D827',
    },
    '&.save:hover': {
      color: '#01B927',
    },
    '&.cancel': {
      color: '#E01E01',
    },
    '&.edit': {
      color: '#999999',
    },
  },
  pagination_btn: {
    '& button:focus': {
      outline: 0,
    },
  },
  input_label: {
    fontSize: '8px',
  },
  icon_column: {
    padding: 0,
  },
});

export const ManageUserColumn = ({ openModal, blockUserAction, user }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '20px',
      align: 'center',
    },
    {
      title: 'อีเมล์',
      dataIndex: 'email',
      width: '250px',
      align: 'center',
      ...renderSearchColumn('email', searchInput),
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'fullName',
      width: '250px',
      align: 'center',
      ...renderSearchColumn('fullName', searchInput),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '100px',
      align: 'center',
    },
    {
      title: 'สถานะผู้ใช้',
      dataIndex: 'is_block',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return <Tag color={!text ? color.load : color.remove}>{!text ? 'active' : 'block'}</Tag>;
      },
    },
    {
      title: 'จัดการสิทธิ์การใช้งาน',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          user &&
          user.role == 'Admin' && (
            <div style={{ display: 'flex' }}>
              <Button
                style={{ backgroundColor: color.edit }}
                className="ant-btn-customize"
                icon={<EditOutlined />}
                onClick={() => openModal('edit', record)}>
                {'แก้ไข'}
              </Button>
              &nbsp;
              {record.role == 'User' && (
                <Button
                  style={{ backgroundColor: color.remove }}
                  className="ant-btn-customize"
                  icon={<StopOutlined />}
                  onClick={() => blockUserAction(record)}>
                  {!record.is_block ? 'บล็อคผู้ใช้' : 'ปลดบล็อคผู้ใช้'}
                </Button>
              )}
            </div>
          )
        );
      },
    },
  ];
};

export const ManageAccountColumn = ({ openModal }) => {
  const searchInput = createRef();

  const tagColorManageAccount = (status) => {
    switch (status) {
      case 'Online':
        return color.load;
      case 'Offline':
        return color.danger;
      case 'Turn off find my':
        return color.on_process;
      case 'Change password':
        return color.edit;
      case 'Apple id inactive':
        return color.clear;
      default:
        return color.disabled;
    }
  };
  const tagColorProcessStatus = (status) => {
    switch (status) {
      case 'PROCESSING':
        return color.on_process;
      default:
        return color.disabled;
    }
  };
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'อีเมล์',
      dataIndex: 'mail',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('mail', searchInput),
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'fullName',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('fullName', searchInput),
    },
    {
      title: 'เบอร์โทรอีเมล',
      dataIndex: 'telMail',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('telMail', searchInput),
      render: (text) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'เบอร์โทรไอคลาวน์',
      dataIndex: 'telIcloud',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('telIcloud', searchInput),
      render: (text) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'วันที่สมัคร',
      dataIndex: 'createDate',
      width: '120px',
      align: 'left',
      sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
    },
    {
      title: 'วันที่ทำสัญญา',
      dataIndex: 'contractDate',
      width: '120px',
      align: 'left',
      // sorter: (a, b) => new Date(a.contractDate).getTime() - new Date(b.contractDate).getTime()
      sorter: (a, b) => new Date(a.contractDate) - new Date(b.contractDate),
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('contractNo', searchInput),
      render: (text, record) => {
        return record.status !== 'INACTIVE' && record.contractNo === '' && (record.contractDate === null || record.contractDate === '') ? (
          <ButtonTheme
            useFor="CUSTOM"
            style={{ color: '#4B4B4B', borderColor: '#A1A1A1', borderRadius: 5 }}
            bgcolor="white"
            title="ระบุสัญญา"
            onClick={() => openModal('changeContract', record)}
          />
        ) : (
          record.contractNo
        );
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'WAIT_REGISTER', text: 'รอสมัครไอคลาวน์' },
        { value: 'ACTIVE', text: 'พร้อมใช้งาน' },
        { value: 'INACTIVE', text: 'กำลังใช้งาน' },
        { value: 'WAIT', text: 'รอจัดการ' },
        { value: 'CLOSED', text: 'ปิดใช่้งาน' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag color={text === 'ACTIVE' ? color.load : text === 'WAIT' ? color.remove : text === 'WAIT_REGISTER' ? color.edit : text === 'INACTIVE' ? color.on_process : color.disabled}>
            {record.statusName}
          </Tag>
        );
      },
    },
    {
      title: 'ผู้สมัคร icloud',
      dataIndex: 'createUser',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('createUser', searchInput),
    },
    {
      title: 'สถานะติดตาม icloud',
      dataIndex: 'icloud_status',
      width: '120px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return record.icloud_status ? <Tag color={tagColorManageAccount(text)}>{record.icloud_status}</Tag> : '';
      },
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'updateDate',
      width: '150px',
      align: 'center',
    },
    {
      title: 'สถานะการประมวลผล',
      dataIndex: 'queue_service_status',
      width: '150px',
      align: 'center',
      render: (text) => {
        return text === '' ? '' : <Tag color={tagColorProcessStatus(text)}>{text === 'PROCESSING' && 'กำลังตรวจสอบ'}</Tag>;
      },
    },
    {
      title: 'จัดการบัญชี',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            <Tooltip placement="topLeft" title={'แก้ไข'} arrowPointAtCenter>
              <Button
                style={{ backgroundColor: color.edit, color: color.white }}
                className="ant-btn-customize"
                icon={<EditOutlined />}
                onClick={() => openModal('edit', record)}></Button>
            </Tooltip>
            &nbsp;
            {(record.status === 'WAIT' || record.status === 'CLOSED') && (
              <Tooltip placement="topLeft" title={'จัดการ'} arrowPointAtCenter>
                <Button
                  style={{ backgroundColor: color.remove, color: color.white }}
                  className="ant-btn-customize"
                  icon={<SettingOutlined />}
                  onClick={() => openModal('manageIcloud', record)}></Button>
              </Tooltip>
            )}
            &nbsp;
            {(record.status === 'WAIT_REGISTER') && (
              <Tooltip placement="topLeft" title={'สมัครไอคลาวน์'} arrowPointAtCenter>
                <Button
                  style={{ backgroundColor: color.success, color: color.white }}
                  className="ant-btn-customize"
                  icon={<FileOutlined />}
                  onClick={() => openModal('icloudRegister', record)}></Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
  ];
};

export const ManageIcloudAccountColumn = ({ openModal }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },

    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'fullName',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('fullName', searchInput),
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('contractNo', searchInput),
    },
    {
      title: 'อีเมล์',
      dataIndex: 'mail',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('mail', searchInput),
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'statusContract',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'ACTIVE', text: 'กำลังใช้งาน' },
        { value: 'WAIT_ADMIN_CONFIRM', text: 'รอเจ้าหน้าที่ตรวจสอบ' },
        { value: 'WAIT_FINANCE_APPROVE', text: 'รอการเงินอนุมัติ' },
        { value: 'WAIT_SIGNATURE', text: 'รอเซ็นสัญญา' },
      ],
      onFilter: (value, record) => record.statusContract.indexOf(value) === 0,
      render: (text) => {
        return (
          <Tag
            color={
              text === 'ACTIVE'
                ? color.success
                : text === 'WAIT_ADMIN_CONFIRM'
                  ? color.manage
                  : text === 'WAIT_FINANCE_APPROVE'
                    ? color.edit
                    : color.create
            }>
            {text === 'ACTIVE'
              ? 'กำลังใช้งาน'
              : text === 'WAIT_ADMIN_CONFIRM'
                ? 'รอเจ้าหน้าที่ตรวจสอบ'
                : text === 'WAIT_FINANCE_APPROVE'
                  ? 'รอการเงินอนุมัติ'
                  : text === 'WAIT_SIGNATURE'
                    ? 'รอเซ็นสัญญา'
                    : '-'}
          </Tag>
        );
      },
    },
    {
      title: 'สถานะไอคลาวน์',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'WAIT_REGISTER', text: 'รอสมัครไอคลาวน์' },
        { value: 'ACTIVE', text: 'พร้อมใช้งาน' },
        { value: 'INACTIVE', text: 'กำลังใช้งาน' },
        { value: 'WAIT', text: 'รอจัดการ' },
        { value: 'CLOSED', text: 'ปิดใช่้งาน' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag color={text === 'ACTIVE' ? color.load : text === 'WAIT' ? color.remove : text === 'WAIT_REGISTER' ? color.edit : text === 'INACTIVE' ? color.on_process : color.disabled}>
            {record.statusName}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex' }}>
            <Button
              style={{ backgroundColor: color.on_process, color: color.white }}
              className="ant-btn-customize"
              icon={<EyeOutlined />}
              onClick={() => openModal('view', record)}>
              {'ดูข้อมูล'}
            </Button>
          </div>
        );
      },
    },
  ];
};

export const ManageTelephoneColumn = ({ openModal }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },

    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('tel', searchInput),
      render: (text, record) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'วันหมดอายุ',
      dataIndex: 'expireDate',
      width: '100px',
      align: 'left',
      sorter: (a, b) => moment(a.expireDate).unix() - moment(b.expireDate).unix(),
    },
    {
      title: 'วันคงเหลือ',
      dataIndex: 'expireRemain',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return (
          <span style={{ color: text < 30 ? color.danger : color.sendAx }}>
            {text || text === 0 ? `${text} วัน ${text < 30 ? ' (เติมเงิน)' : ''}` : ''}
          </span>
        );
      },
      sorter: (a, b) => a.expireRemain - b.expireRemain,
    },
    {
      title: 'จำนวนที่ใช้เบอร์นี้',
      dataIndex: 'amountSpent',
      width: '80px',
      align: 'right',
      sorter: (a, b) => a.amountSpent - b.amountSpent,
    },
    {
      title: 'สถานะเปิดใช้สมัคร iCloud',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'ACTIVE', text: 'เปิดใช้งาน' },
        { value: 'INACTIVE', text: 'ปิดใช้งาน' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return <Tag color={text === 'ACTIVE' ? color.load : color.remove}>{record.statusName}</Tag>;
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '100px',
      align: 'right',
      // sorter: (a, b) => a.amountSpent - b.amountSpent,
    },
    {
      title: '',
      dataIndex: 'action',
      width: '60px',
      align: 'center',
      render: (text, record) => {
        return (
          <Button style={{ backgroundColor: color.edit }} className="ant-btn-customize" icon={<EditOutlined />} onClick={() => openModal(record)}>
            {'แก้ไข'}
          </Button>
        );
      },
    },
  ];
};

export const RefinanceColumn = ({ openModal }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },

    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('contract_no', searchInput),
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'customerName',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('customerName', searchInput),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'WAIT_FINANCE_APPROVE', text: 'รอตรวจสอบ' },
        { value: 'SUCCESS', text: 'จ่ายเงินสำเร็จ' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return <Tag color={text === 'WAIT_FINANCE_APPROVE' ? color.on_process : color.load}>{record.statusName}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            <Button
              style={{ backgroundColor: color.edit }}
              className="ant-btn-customize"
              icon={<ClockCircleOutlined />}
              onClick={() => openModal(record)}>
              {'ตรวจสอบ'}
            </Button>
          </div>
        );
      },
    },
  ];
};

export const MonitorCustomerColumn = ({ options, openModal, user, getBlacklistLink }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'fullName',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('fullName', searchInput),
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'phoneNumber',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('phoneNumber', searchInput),
      render: (text) => {
        return convertStrToFormat(text, 'phone_number');
      },
    },
    {
      title: 'facebook',
      dataIndex: 'facebook',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('facebook', searchInput),
    },
    {
      title: 'lineId',
      dataIndex: 'lineId',
      width: '80px',
      align: 'left',
      ...renderSearchColumn('lineId', searchInput),
    },
    {
      title: 'เอกสารรายได้',
      dataIndex: 'typeStatement',
      width: '150px',
      align: 'left',
      filters: [
        { value: 'NO', text: 'ไม่มี' },
        { value: 'CURRENT', text: 'สลิปเงินเดือนปัจจุบัน' },
        { value: '3MONTH', text: 'สเตทเมนท์' },
      ],
      onFilter: (value, record) => record.typeStatement.indexOf(value) === 0,
      render: (text) => {
        return text === 'NO' ? 'ไม่มี' : text === 'CURRENT' ? 'สลิปเงินเดือนปัจจุบัน' : 'สเตทเมนท์';
      },
    },
    {
      title: 'วันที่ลงทะเบียน',
      dataIndex: 'registerDate',
      width: '120px',
      align: 'left',
      // sorter: (a, b) => moment(a.registerDate).unix() - moment(b.registerDate).unix(),
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '120px',
      align: 'center',
      filters: [
        { value: 'INSTALLMENT', text: 'ผ่อนดาวน์' },
        { value: 'REFINANCE', text: 'ไอโฟนแลกเงิน' },
      ],
      onFilter: (value, record) => {
        return record.type.code === value;
      },
      render: (text, record) => {
        return record.type.text === 'รีไฟแนนซ์' ? 'ไอโฟนแลกเงิน' : record.type.text;
      },
    },
    {
      title: 'ลักษณะสินค้า',
      dataIndex: 'productHand',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return record?.productHands;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'statusName',
      width: '120px',
      align: 'center',
      // fixed: 'right',
      filters: options.optionsConsiderStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record?.status?.code === value;
      },
      render: (text, record) => {
        return (
          <Tag
            color={
              ['WAIT', 'WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE'].includes(record?.status?.code)
                ? color.edit
                : ['REJECTED', 'NO_PROMISE', 'CANCEL_PROMISE', 'FOLLOWUP'].includes(record?.status?.code)
                  ? color.remove
                  : ['SUCCESS_PROMISE'].includes(record?.status?.code)
                    ? color.success
                    : ['CANCEL_CONSIDER'].includes(record?.status?.code)
                      ? color.disabled
                      : color.on_process
            }>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'Walk in',
      dataIndex: 'walk_in',
      width: '80px',
      align: 'center',
      render: (text) => {
        return text === 'Walk in' ? <CheckOutlined style={{ color: color.success }} /> : <></>;
      },
      // render: (text) => {
      //   return text === 'Walk in' ? <CheckOutlined style={{ color: color.success }} /> : <CloseOutlined style={{ color: color.danger }} />
      // },
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return text === 'app' ? 'App' : text === 'web' ? 'Web' : text;
      },
    },
    {
      title: 'สถานะ blacklist',
      dataIndex: 'statusBlacklist',
      width: '120px',
      align: 'center',
      filters: [
        { text: 'อยู่ใน blacklist', value: 'อยู่ใน blacklist' },
        { text: 'ผ่าน', value: 'ผ่าน' },
        { text: 'รอระบบตรวจสอบ', value: 'รอระบบตรวจสอบ' },
      ],
      onFilter: (value, record) => {
        return record.statusBlacklistName === value;
      },
      render: (text, record) => {
        return (
          <div style={{ cursor: 'pointer' }} onClick={() => getBlacklistLink(record.profile.idCard, text)}>
            <Tag color={text === 'BLACKLIST' || text === 'WARNING' ? color.remove : text === 'PASS' ? color.success : color.edit}>
              {record.statusBlacklistName}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'วันเวลาที่นัดหมาย',
      dataIndex: 'appointmentDate',
      width: '120px',
      align: 'left',
    },
    {
      title: 'สาขาที่นัดทำสัญญา',
      dataIndex: 'branch',
      width: '120px',
      align: 'left',
      filters: options.optionsBranch.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        options.optionsBranch.map((el) => {
          if (value == el.value) {
            value = el.label;
          } else if (value == 'ไม่มี') {
            value = '';
          }
        });
        return record.branch === value;
      },
      render: (text) => {
        return text || '';
      },
    },
    {
      title: 'สาขาที่สนใจ',
      dataIndex: 'interestedBranch',
      width: '120px',
      align: 'left',
      filters: options.optionsBranchUser.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.interestedBranch === value;
      },
      render: (text, record) => {
        let branch = '';
        options.optionsBranchUser.map((el) => {
          if (record.interestedBranch === el.value) {
            branch = el.label;
          }
        });
        return branch;
      },
    },
    {
      title: 'มีสัญญา',
      dataIndex: 'promise',
      width: '120px',
      align: 'left',
    },
    {
      title: 'เกรดลูกค้า',
      dataIndex: 'userGrade',
      width: '110px',
      align: 'center',
      filters: [
        { value: '-', text: 'ไม่มีเกรดลูกค้า' },
        { value: 'A', text: 'A (0-5' },
        { value: 'B', text: 'B (6-15)' },
        { value: 'C', text: 'C (16-59)' },
        { value: 'D', text: 'D (60+)' },
        { value: '?', text: '? (ยังไม่ระบุ)' },
        { value: 'V', text: 'V (VIP)' },
        { value: 'X', text: 'X (Blocked)' },
      ],
      onFilter: (value, record) => {
        if (record.userGrade == '') {
          record.userGrade = '-';
        }
        return record.userGrade.indexOf(value) === 0;
      },
      // render: (text) => {
      //   return text === 'D' ? 'D' : text === 'A' ? 'A' : text === 'B' ? 'B' : text === 'C' ? 'C' : '';
      // },
    },
    {
      title: 'A Score',
      dataIndex: 'aLocalScale',
      width: '120px',
      align: 'center',
    },
    // {
    //   title: 'ช่องทางติดต่อหลัก',
    //   dataIndex: 'main_social',
    //   // width: '150px',
    //   align: 'right',
    //   // fixed:"right",
    //   render: (text, record) => {
    //     return (
    //       <TextSmall
    //         cursor={'pointer'}
    //         onClick={() => openModal(record, 'mainSocial')}
    //         icon={<EditOutlined style={{borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px'}} />}
    //         text={text || ''}
    //       />
    //     );
    //   },
    // },
    {
      title: 'หมายเหตุ(สำหรับเจ้าหน้าที่)',
      dataIndex: 'remark',
      // width: '150px',
      align: 'right',
      // fixed:"right",
      render: (text, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'remark')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={text || ''}
          />
        );
      },
    },
    {
      title: 'social id (chatcone)',
      dataIndex: 'social_id',
      width: '150px',
      key: 'social_id',
      align: 'center',
      render: (text, record) => {
        const social_id = record?.chatcone?.social_id;
        return (
          <>
            {social_id ? <Tag color={color.success}>มี</Tag> : <Tag color={color.clear}>ไม่มี</Tag>}
            <EditOutlined
              style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }}
              onClick={() => openModal(record, 'EDIT_SOCIAL_ID')}
            />
          </>
        );
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        let btnName;
        let iconStyle;
        let bgcolor;
        switch (record?.status?.code) {
          case 'WAIT':
            btnName = 'เริ่มพิจารณา';
            iconStyle = <CaretRightOutlined />;
            bgcolor = color.start;
            break;
          case 'CONSIDERING':
            btnName = 'กำลังพิจารณา';
            iconStyle = <ClockCircleOutlined />;
            bgcolor = color.on_process;
            break;
          case 'WAIT_APPOINTMENT_PROMISE':
          case 'WAIT_PROMISE':
          case 'FOLLOWUP':
          case 'NO_PROMISE':
          case 'CANCEL_PROMISE':
          case 'SUCCESS_PROMISE':
          case 'REJECTED':
            btnName = 'ดูข้อมูล';
            iconStyle = <EyeOutlined />;
            bgcolor = color.submit;
            break;
          default:
            break;
        }

        return user && user.role == 'Admin' ? (
          <div style={{ display: 'flex' }}>
            {[
              'WAIT',
              'CONSIDERING',
              'WAIT_APPOINTMENT_PROMISE',
              'WAIT_PROMISE',
              'FOLLOWUP',
              'NO_PROMISE',
              'CANCEL_PROMISE',
              'SUCCESS_PROMISE',
              'REJECTED',
            ].includes(record?.status?.code) && (
                <Tooltip placement="topLeft" title={btnName} arrowPointAtCenter>
                  <Button
                    style={{ backgroundColor: bgcolor, color: color.white }}
                    className="ant-btn-customize"
                    icon={iconStyle}
                    onClick={() => openModal(record, 'APPROVE')}
                  />
                  {/* {btnName} */}
                </Tooltip>
              )}
            &nbsp;
            {/* Chatcone */}
            {record?.chatcone?.social_id && ['WAIT_APPOINTMENT_PROMISE', 'FOLLOWUP'].includes(record?.status?.code) && (
              <>
                &nbsp;
                <Tooltip placement="topLeft" title={'แจ้งเตือนนัดทำสัญญา'} arrowPointAtCenter>
                  <Button
                    style={{ backgroundColor: '#F3CDD5', color: color.white }}
                    className="ant-btn-customize"
                    icon={<BellOutlined />}
                    onClick={() => openModal(record, 'SEND_CHATCONE')}
                  />
                </Tooltip>
              </>
            )}
            &nbsp;
            {['WAIT_APPOINTMENT_PROMISE', 'FOLLOWUP'].includes(record?.status?.code) && (
              <Tooltip placement="topLeft" title={'ระบุวันติดตาม'} arrowPointAtCenter>
                <Button
                  style={{ backgroundColor: color.remove, color: color.white }}
                  className="ant-btn-customize"
                  icon={<CalendarOutlined />}
                  onClick={() => openModal(record, 'FOLLOWUP')}
                />
              </Tooltip>
            )}
            &nbsp;
            {['WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'FOLLOWUP'].includes(record?.status?.code) && (
              <>
                <Tooltip placement="topLeft" title={'นัดทำสัญญา'} arrowPointAtCenter>
                  <Button
                    style={{ backgroundColor: color.create, color: color.white }}
                    className="ant-btn-customize"
                    icon={<CalendarOutlined />}
                    onClick={() => openModal(record, 'APPOINTMENT')}
                  />
                </Tooltip>
              </>
            )}
            &nbsp;
            {['WAIT_APPOINTMENT_PROMISE', 'FOLLOWUP'].includes(record?.status?.code) && (
              <>
                <Tooltip placement="topLeft" title={'ไม่นัดทำสัญญา'} arrowPointAtCenter>
                  <Button
                    style={{ backgroundColor: color.danger, color: color.white }}
                    className="ant-btn-customize"
                    icon={<CloseCircleOutlined />}
                    onClick={() => openModal(record, 'NO_APPOINTMENT')}
                  />
                </Tooltip>
              </>
            )}
            &nbsp;
            {['WAIT', 'CONSIDERING', 'WAIT_APPOINTMENT_PROMISE', 'WAIT_PROMISE', 'FOLLOWUP', 'NO_PROMISE', 'REJECTED'].includes(
              record?.status?.code,
            ) && (
                <>
                  <Tooltip placement="topLeft" title={'ไม่ใช้งาน'} arrowPointAtCenter>
                    <Button
                      style={{ backgroundColor: color.clear, color: color.white }}
                      className="ant-btn-customize"
                      icon={<StopOutlined />}
                      onClick={() => openModal(record, 'CANCEL_CONSIDER')}
                    />
                  </Tooltip>
                </>
              )}
          </div>
        ) : user && user.role == 'User' ? (
          <div style={{ display: 'flex' }}>
            {record?.status?.code === 'WAIT' && (
              <Button
                style={{ backgroundColor: color.edit, color: color.white }}
                className="ant-btn-customize"
                icon={<EditOutlined />}
                onClick={() => openModal(record, 'APPROVE')}>
                {'แก้ไข'}
              </Button>
            )}
          </div>
        ) : null;
      },
    },
  ];
};

export const StockProductsPickColumn = () => {
  return [
    {
      title: 'NO',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },

    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'จำนวนจอง',
      dataIndex: 'countReserve',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนสต็อก',
      dataIndex: 'countStock',
      width: '80px',
      align: 'right',
    },
    {
      title: 'จำนวนที่ต้องหยิบ',
      dataIndex: 'countPick',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return <TextSmall text={text <= 0 ? `${text} (ไม่ต้องจัด)` : text} />;
      },
    },
  ];
};

export const LineProductsPickColumn = ({ onChange }) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
    }
    return tag;
  };
  return [
    {
      title: 'Line No.',
      dataIndex: 'line_no',
      width: '40px',
      align: 'center',
      render: (text, record) => {
        return +text;
      },
    },

    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '80px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'S/N',
      dataIndex: 'serial_number',
      width: '100px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
      // render: (text, record) => {
      //   return <TextSmall text={text <= 0?`${text} (ไม่ต้องจัด)`:text}
      // />;
      // },
    },
    {
      title: 'Apple Status',
      dataIndex: 'appleStatus',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return (
          <Tag color={record.appleStatus == 'DEP' ? color.success : record.appleStatus == 'NON_DEP' ? color.on_process : color.remove}>
            {record.appleStatus}
          </Tag>
        );
      },
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return record.serial_status === null || record.serial_status === undefined || record.serial_status === '' ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '150px',
      align: 'center',
      // render: (text, record) => {
      //   return <TextSmall text={text <= 0?`${text} (ไม่ต้องจัด)`:text}
      // />;
      // },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        return (
          <div>
            {['RELEASE', 'CANCEL', 'PICK'].includes(record.status) && (
              <Button
                style={{ backgroundColor: ['RELEASE', 'PICK'].includes(record.status) ? color.danger : color.success, color: '#fff' }}
                className="ant-btn-customize"
                icon={['RELEASE', 'PICK'].includes(record.status) ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                onClick={() => onChange(record, 'status')}>
                {['RELEASE', 'PICK'].includes(record.status) ? 'CANCEL' : record.serial_number === '' ? 'PICK' : 'RELEASE'}
              </Button>
            )}
          </div>
        );
      },
    },
  ];
};

export const LineProductsNonSerialPickColumn = ({ onChange, onRemove, options }) => {
  return [
    {
      title: 'Line No.',
      dataIndex: 'line_no',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return 100 + (index + 1);
      },
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_code',
      width: '400px',
      align: 'left',
      errors: '',
      value: '',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'item_code',
        title: 'ชื่อสินค้า',
        dataOption: options.optionItems,
        handleChange: onChange,
      }),
    },
    {
      title: 'จำนวน',
      dataIndex: 'qty',
      width: '100px',
      align: 'right',
      errors: '',
      value: '',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'qty',
        title: 'จำนวน',
        handleChange: onChange,
      }),
    },
    {
      title: '',
      dataIndex: 'action',
      width: '20px',
      align: 'center',
      render: (text, record) => {
        return <DeleteOutlineIcon color="error" fontSize="medium" onClick={() => onRemove(record.key)} style={{ cursor: 'pointer' }} />;
      },
    },
  ];
};

export const LineProductsRecieveColumn = () => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
    }
    return tag;
  };
  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'Line No.',
      dataIndex: 'line_no',
      width: '40px',
      align: 'center',
    },

    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '80px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '150px',
      align: 'left',
    },
    {
      title: 'S/N',
      dataIndex: 'serial_number',
      width: '100px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return record.serial_status === undefined ? (
          <Text>{''}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },

    // {
    //   title: '',
    //   dataIndex: 'action',
    //   width: '80px',
    //   align: 'right',
    //   render: (text, record) => {

    //     return (
    //       <div>
    //         {['RELEASE', 'CANCEL'].includes(record.status) && <Button
    //           style={{backgroundColor: record.status ==="RELEASE"?color.danger:color.success,color:'#fff'}}
    //           className="ant-btn-customize"
    //           icon={record.status ==="RELEASE"?<CloseCircleOutlined/>:<CheckCircleOutlined />}
    //           onClick = {()=>onChange(record,"status")}
    //           >
    //          {record.status ==="RELEASE"?"CANCEL":"RELEASE"}
    //         </Button>}
    //       </div>
    //     );
    //   },
    // }
  ];
};

export const ReportMonitorStockColumn = () => {
  const searchInput = createRef();
  let branchSearch = localStorage.getItem('branchReportMonitorStock');
  // let itemTypeSearch = localStorage.getItem('itemTypeReportMonitorStock')
  let serialStatusSearch = localStorage.getItem('serialStatusReportMonitorStock');

  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '80px',
      align: 'left',
      ...renderSearchColumn('item_code', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('item_name', searchInput),
    },
    {
      title: 'จำนวน',
      dataIndex: 'stock',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: 'ระหว่างส่ง',
      dataIndex: 'inTransit',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.inTransit - b.inTransit,
    },
    {
      title: 'จอง',
      dataIndex: 'reserve',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.reserve - b.reserve,
    },
    {
      title: 'คงเหลือ',
      dataIndex: 'remaining',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.remaining - b.remaining,
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '80px',
      render: (text, record) => {
        let type = '';
        if (record.item_name.includes('(พิเศษ)')) {
          type = '3';
        } else {
          type = '1';
        }

        return (
          <ButtonTheme
            useFor="VIEW"
            title="รายละเอียด"
            onClick={() =>
              window.open(
                `/report-stock-serial?branch=${branchSearch}&itemName=${record.item_name}&itemType=${type}&serialStatus=${serialStatusSearch}`,
                '_blank',
              )
            }
          />
        );
      },
    },
  ];
};

export const ReportAdjustStockColumn = ({ options }) => {
  const searchInput = createRef();
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
    }
    return tag;
  };
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'document_no',
      width: '150px',
      align: 'center',
      ...renderSearchColumn('type', searchInput),
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '100px',
      align: 'center',
      ...renderSearchColumn('type', searchInput),
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('item_code', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '350px',
      align: 'left',
      ...renderSearchColumn('item_name', searchInput),
    },
    {
      title: 'ซีเรียล',
      dataIndex: 'serial_number',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('serial_number', searchInput),
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return record.serial_status === '' ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'ต้นทุน',
      dataIndex: 'cost',
      width: '100px',
      align: 'right',
      render: (text) => {
        return convertStrToFormat(text, 'money');
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'สาขา',
      dataIndex: 'branch_name',
      width: '200px',
      align: 'left',
      filters: options.branch,
      onFilter: (value, record) => record.branch.indexOf(value) === 0,
    },
    {
      title: 'วันเวลาทำรายการ',
      dataIndex: 'create_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return moment(text).format('YYYY-MM-DD HH:mm');
      },
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
    },
    {
      title: 'ผู้ทำรายการ',
      dataIndex: 'userCreate',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('userCreate', searchInput),
    },
  ];
};

export const UnlockIcloudAccountingColumn = ({ options, openModal, openReceiptNewTabWithGet, user }) => {
  const searchInput = createRef();

  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'NO_STATUS':
        tag = color.clear;
        break;
      case data === 'APPROVED':
        tag = color.success;
        break;
    }
    return tag;
  };
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
      render: (text, record, i) => {
        return <div style={{ display: 'flex' }}>{i + 1}</div>;
      },
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'user_name',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('user_name', searchInput),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.user_name}</div>;
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('contract_no', searchInput),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.contract_no}</div>;
      },
    },
    {
      title: 'วันที่ปิดสัญญา',
      dataIndex: 'closed_contract_date',
      width: '120px',
      align: 'left',
      sorter: (a, b) => moment(a.closed_contract_date).unix() - moment(b.closed_contract_date).unix(),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{moment(record.closed_contract_date).format('DD/MM/YYYY')}</div>;
      },
    },
    {
      title: 'ผู้แก้ไข',
      dataIndex: 'updated_admin_name',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('updated_admin_name', searchInput),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.updated_admin_name}</div>;
      },
    },
    {
      title: 'เกรดหลังทำสัญญา',
      dataIndex: 'grade',
      width: '120px',
      align: 'center',
      filters: [
        { value: '-', text: 'ไม่มีเกรดลูกค้า' },
        { value: 'A', text: 'A (0-5' },
        { value: 'B', text: 'B (6-15)' },
        { value: 'C', text: 'C (16-59)' },
        { value: 'D', text: 'D (60+)' },
        { value: '?', text: '? (ยังไม่ระบุ)' },
        { value: 'V', text: 'V (VIP)' },
        { value: 'X', text: 'X (Blocked)' },
      ],
      onFilter: (value, record) => {
        if (record.grade == '') {
          record.grade = '-';
        }
        return record.grade.indexOf(value) === 0;
      },
      render: (text, record) => {
        return <div style={{ display: 'center' }}>{record.grade}</div>;
      },
    },
    {
      title: 'SN',
      dataIndex: 'serialnumber',
      width: '120px',
      align: 'center',
      // sorter: (a, b) => moment(a.registerDate).unix() - moment(b.registerDate).unix(),
    },
    {
      title: 'สถานะการส่งรหัส',
      dataIndex: 'approveStatus',
      width: '120px',
      align: 'center',
      // fixed: 'right',
      filters: options.optionsApprove.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.approve_status.code === value;
      },
      render: (text, record) => {
        return <Tag color={tagColor(record.approve_status.code)}>{record.approve_status.text}</Tag>;
      },
    },
    {
      title: 'วันที่กดอนุมัติ',
      dataIndex: 'approve_date',
      width: '100px',
      align: 'left',
      sorter: (a, b) => moment(a.approve_date).unix() - moment(b.approve_date).unix(),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.approve_date !== '' ? moment(record.approve_date).format('DD/MM/YYYY') : ''}</div>;
      },
    },
    {
      title: 'social id (chatcone)',
      dataIndex: 'social_id',
      width: '150px',
      key: 'social_id',
      align: 'center',
      render: (text, record) => {
        const social_id = record?.chatcone?.social_id;
        return social_id ? <Tag color={color.success}>มี</Tag> : <Tag color={color.clear}>ไม่มี</Tag>;
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        let btnName;
        let iconStyle;
        let bgcolor;
        switch (record.approve_status.code) {
          case 'NO_STATUS':
            btnName = 'Approve';
            iconStyle = <CaretRightOutlined />;
            bgcolor = color.on_process;
            break;
          case 'APPROVED':
            btnName = 'ดูรหัส iCloud';
            iconStyle = <ClockCircleOutlined />;
            bgcolor = color.theme;
            break;
          default:
            break;
        }

        localStorage.setItem('contractNo', record.contract_no);

        return user && user.role == 'Admin' ? (
          <div style={{ display: 'flex' }}>
            {['NO_STATUS', 'APPROVED'].includes(record.approve_status.code) && (
              <Tooltip placement="topLeft" title={'ดูรายการรับชำระ'}>
                {/* <Link to={'/payment'}> */}
                <Button
                  style={{ backgroundColor: color.load, color: color.white }}
                  className="ant-btn-customize"
                  // onClick={() => openReceipt(record)}
                  onClick={() =>
                    // openInNewTab('/payment')
                    openReceiptNewTabWithGet('/payment', { contractNo: record.contract_no })
                  }>
                  ดูรายการรับชำระ
                </Button>
                {/* </Link> */}
              </Tooltip>
            )}
            &nbsp;
            {['NO_STATUS'].includes(record.approve_status.code) && (
              <Tooltip placement="topLeft" title={btnName}>
                <Button
                  style={{ backgroundColor: bgcolor, color: color.white }}
                  className="ant-btn-customize"
                  // icon={iconStyle}
                  onClick={() => openModal(record, 'APPROVE_PASSWORD')}>
                  {btnName}
                </Button>
                {/* {btnName} */}
              </Tooltip>
            )}
            &nbsp;
            {['APPROVED'].includes(record.approve_status.code) && (
              <Tooltip placement="topLeft" title={btnName}>
                <Button
                  style={{ backgroundColor: bgcolor, color: color.white }}
                  className="ant-btn-customize"
                  // icon={iconStyle}
                  onClick={() => openModal(record, 'VIEW_PASSWORD')}>
                  {btnName}
                </Button>
                {/* {btnName} */}
              </Tooltip>
            )}{' '}
            &nbsp;
            {record?.chatcone?.social_id && (
              <Tooltip placement="topLeft" title={'ส่งแจ้งเตือน'}>
                <Button
                  style={{ backgroundColor: color.clear, color: color.white }}
                  className="ant-btn-customize"
                  onClick={() => openModal(record, 'CHATCONE_HISTORY')}>
                  {'ส่งแจ้งเตือน'}
                </Button>
              </Tooltip>
            )}
          </div>
        ) : null;
      },
    },
  ];
};

export const UnlockIcloudCRMColumn = ({ options, openModal, user }) => {
  const searchInput = createRef();

  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'NO_STATUS':
        tag = color.clear;
        break;
      case data === 'CONTACT_ADMIN':
        tag = color.manage;
        break;
      case data === 'NEW_CONTRACT':
        tag = color.create;
        break;
      case data === 'CONTRACT_COMPLETED':
        tag = color.success;
        break;
    }
    return tag;
  };

  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
      render: (text, record, i) => {
        return <div style={{ display: 'flex' }}>{i + 1}</div>;
      },
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'user_name',
      width: '200px',
      align: 'left',
      // ...renderSearchColumn('fullName', searchInput),
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.user_name}</div>;
      },
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.tel, 'phone_number');
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '120px',
      align: 'left',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.contract_no}</div>;
      },
    },
    {
      title: 'วันที่ปิดสัญญา',
      dataIndex: 'closed_contract_date',
      width: '120px',
      align: 'left',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{moment(record.closed_contract_date).format('DD/MM/YYYY')}</div>;
      },
      // sorter: (a, b) => moment(a.registerDate).unix() - moment(b.registerDate).unix(),
    },
    {
      title: 'ผู้แก้ไข',
      dataIndex: 'updated_admin_name',
      width: '120px',
      align: 'left',
      render: (text, record) => {
        return <div style={{ display: 'flex' }}>{record.updated_admin_name}</div>;
      },
    },
    {
      title: 'สถานะการส่งรหัส',
      dataIndex: 'unlockStatus',
      width: '120px',
      align: 'center',
      // fixed: 'right',
      filters: options.optionsCRM.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.unlock_status.code === value;
      },
      render: (text, record) => {
        return <Tag color={tagColor(record.unlock_status.code)}>{record.unlock_status.text}</Tag>;
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '300px',
      align: 'right',
      // fixed:"right",
      render: (text, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'REMARK_UNLOCK')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={text || ''}
          />
        );
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        let btnName;
        let iconStyle;
        let bgcolor;
        btnName = 'แก้ไข';
        iconStyle = <EditOutlined />;
        bgcolor = color.edit;

        return user && user.role == 'Admin' ? (
          <div style={{ display: 'flex' }}>
            {['NO_STATUS', 'CONTACT_ADMIN', 'NEW_CONTRACT', 'CONTRACT_COMPLETED'].includes(record.unlock_status.code) && (
              <Tooltip placement="topLeft" title={btnName}>
                <Button
                  style={
                    record.unlock_status.code !== 'CONTRACT_COMPLETED'
                      ? { backgroundColor: bgcolor, color: color.black }
                      : { backgroundColor: color.disabled, color: color.white }
                  }
                  className="ant-btn-customize"
                  disabled={!!(record.unlock_status.code === 'CONTRACT_COMPLETED')}
                  icon={iconStyle}
                  onClick={() => openModal(record, 'CHANGE_STATUS')}>
                  {btnName}
                </Button>
                {/* {btnName} */}
              </Tooltip>
            )}{' '}
          </div>
        ) : null;
      },
    },
  ];
};
export const BlacklistCustomerColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'id_card',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('id_card', searchInput),
    },
    {
      title: 'ชื่อ',
      dataIndex: 'name',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('name', searchInput),
    },
    {
      title: 'เบอร์',
      dataIndex: 'tel',
      width: '100px',
      align: 'left',
      ...renderSearchColumn('tel', searchInput),
    },
    {
      title: 'ลิงก์',
      dataIndex: 'url',
      width: '100px',
      align: 'left',
      render: (text, record) => {
        const urlLink = record.source ? record.link : record.ref;
        if (Array.isArray(urlLink) && urlLink[0] && urlLink[0].url) {
          return (
            <div>
              {urlLink.map((l, i) => {
                return l.url.map((linkR) => {
                  return (
                    <div key={`${linkR}_${i}`}>
                      <a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR}>
                        {linkR}
                      </a>
                    </div>
                  );
                });
              })}
            </div>
          );
        }

        return (
          <div>
            {urlLink.map((l, i) => {
              let linkR = l;
              return (
                linkR && (
                  <div key={`${linkR}_${i}`}>
                    <a target="_blank" rel="noreferrer" href={!linkR.includes('https') ? `https://${linkR.trim()}` : linkR}>
                      {linkR}
                    </a>
                  </div>
                )
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'คำอธิบายเพิ่ม',
      dataIndex: 'description',
      width: '150px',
      align: 'left',
      render: (text, record) => {
        const urlLink = record.source && record.link;
        if (urlLink && urlLink[0]) {
          return urlLink[0].description || text;
        }
        return text;
      },
    },
    {
      title: 'แหล่งที่มา',
      dataIndex: 'source',
      width: '100px',
      align: 'center',
      ...renderSearchColumn('source', searchInput),
      render: (text, record) => {
        return text || '-';
      },
    },
    {
      title: 'ค้นหาโดย',
      dataIndex: 'runBy',
      width: '100px',
      align: 'center',
      ...renderSearchColumn('runBy', searchInput),
      render: (text, record) => {
        return text || '-';
      },
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'create_date',
      width: '100px',
      align: 'left',
      render: (text, record) => {
        return <span>{moment(text).format('DD/MM/YYYY HH:mm')}</span>;
      },
    },
    {
      title: 'ผู้ที่สร้าง',
      dataIndex: 'create_name',
      width: '150px',
      align: 'left',
      render: (text, record) => {
        return <span>{(record && record.user_create && record.user_create.name) || ''}</span>;
      },
    },
  ];
};

export const PackageDetailColumn = () => {
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
      className: 'head-child-table',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '400px',
      align: 'left',
      className: 'head-child-table',
    },
    {
      title: 'จำนวน',
      dataIndex: 'qty',
      width: '100px',
      align: 'right',
      className: 'head-child-table',
    },
    {
      title: 'ราคา',
      dataIndex: 'cost',
      width: '100px',
      align: 'right',
      className: 'head-child-table',
      render: (text) => {
        return convertStrToFormat(text, 'money');
      },
    },
  ];
};

export const ProductsNonStockColumn = (onClick) => {
  const searchInput = createRef();
  return [
    {
      title: 'ลำดับ',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '120px',
      align: 'left',
      ...renderSearchColumn('item_code', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '330px',
      align: 'left',
      ...renderSearchColumn('item_name', searchInput),
    },
    {
      title: 'จำนวนแพคเก็จ',
      dataIndex: 'packageNum',
      width: '100px',
      align: 'right',
      sorter: (a, b) => a.packageNum - b.packageNum,
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      render: (text, record) => {
        return (
          <div>
            <Button
              style={{ backgroundColor: color.remove, color: color.white }}
              className="ant-btn-customize"
              icon={<SettingOutlined />}
              onClick={() => onClick('detailPackage', record)}>
              {'จัดการ'}
            </Button>
          </div>
        );
      },
    },
  ];
};

export const PackageColumn = (onClick) => {
  const searchInput = createRef();
  return [
    {
      title: 'ลำดับ',
      dataIndex: 'key',
      width: '40px',
      align: 'center',
      className: 'head-table',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'ชื่อแพคเก็จ',
      dataIndex: 'package_name',
      width: '400px',
      align: 'left',
      className: 'head-table',
      ...renderSearchColumn('packageName', searchInput),
    },
    {
      title: 'งวด',
      dataIndex: 'periods',
      width: '100px',
      align: 'right',
      className: 'head-table',
      render: (text) => {
        return <span>{text && text.toString()}</span>;
      },
    },
    {
      title: 'สถานะใช้งาน',
      dataIndex: 'status_active',
      width: '100px',
      align: 'right',
      className: 'head-table',
      filters: [
        { value: 'ACTIVE', text: 'ใช้งาน' },
        { value: 'INACTIVE', text: 'ไม่ใช้งาน' },
      ],
      onFilter: (value, record) => record.status_active.indexOf(value) === 0,
      render: (text, record) => {
        return <Tag color={text === 'ACTIVE' ? color.success : color.remove}>{text === 'ACTIVE' ? 'ใช้งาน' : 'ไม่ใช้งาน'}</Tag>;
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '80px',
      align: 'center',
      className: 'head-table',
      render: (text, record) => {
        return (
          <div>
            <Button
              style={{ backgroundColor: color.edit, color: color.white }}
              className="ant-btn-customize"
              icon={<EditOutlined />}
              onClick={() => onClick('edit', record)}>
              {'แก้ไข'}
            </Button>
          </div>
        );
      },
    },
  ];
};

//////////////////////หน้ารายละเอียดสัญญา///////////////

export const DetailContractColumn = () => {
  return [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return <span>{`${record.name} SN=${record.serialNumber}`}</span>;
      },
    },
  ];
};

export const DetailPackageByContractColumn = () => {
  return [
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      key: 'item_code',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      key: 'item_name',
    },
    {
      title: 'จำนวน',
      dataIndex: 'qty',
      key: 'qty',
      align: 'right',
    },
    {
      title: 'ราคา',
      dataIndex: 'cost',
      key: 'cost',
      align: 'right',
    },
  ];
};

export const DetailInstallmentByContractColumn = () => {
  return [
    {
      title: 'งวดที่',
      dataIndex: 'period',
      key: 'period',
    },
    {
      title: 'วันที่ครบกำหนดชำระ',
      dataIndex: 'pdate',
      key: 'pdate',
    },
    {
      title: 'ค่างวด',
      dataIndex: 'periodamount',
      key: 'periodamount',
      align: 'right',
      render: (text, record) => {
        return <span>{convertStrToFormat(record.periodamount, 'money')}</span>;
      },
    },
  ];
};

////รายการค่าใช้จ่ายอื่น///////
export const OtherPaymentColumn = () => {
  return [
    {
      title: 'วันที่',
      dataIndex: 'create_date',
      key: 'create_date',
      width: '120px',
      render: (text, record) => {
        return moment(text, 'YYYY-MM-DD').format('DD/MM/YYYY');
      },
    },
    {
      title: 'จำนวน',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      width: '100px',
      render: (_text, record) => {
        return <span>{convertStrToFormat(record.amount, 'money')}</span>;
      },
    },
    {
      title: 'รายละเอียด',
      dataIndex: 'type',
      key: 'type',
      width: '200px',
      align: 'left',
    },
  ];
};
//////////////////////หน้ารายละเอียดสัญญา///////////////

export const LineItemsNonSerialRecieveColumn = (onChange) => {
  return [
    {
      title: 'Line No.',
      dataIndex: 'line_no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '300px',
      align: 'left',
    },
    {
      title: 'จำนวน',
      dataIndex: 'qty',
      width: '100px',
      align: 'center',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'qty',
        title: 'จำนวน',
        handleChange: onChange,
      }),
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '80px',
      align: 'center',
      render: (text) => {
        return text ? <Tag color={text === 'RECEIVE' ? color.success : color.remove}>{text}</Tag> : '';
      },
    },
  ];
};

export const LineAdjustStockNonSerialColumn = ({ onChange, onRemove, options }) => {
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_code',
      width: '350px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'item_code',
        title: 'ชื่อสินค้า',
        dataOption: options.optionItems,
        handleChange: onChange,
      }),
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '180px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'branch',
        title: 'สาขา',
        dataOption: options.optionBranch,
        handleChange: onChange,
      }),
    },
    {
      title: 'ต้นทุน',
      dataIndex: 'cost',
      width: '80px',
      align: 'right',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'cost',
        title: 'ต้นทุน',
        handleChange: onChange,
      }),
    },
    {
      title: 'จำนวน',
      dataIndex: 'stock',
      width: '80px',
      align: 'right',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'stock',
        title: 'จำนวน',
        handleChange: onChange,
      }),
    },
    {
      title: '',
      dataIndex: 'action',
      width: '20px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <DeleteOutlineIcon color="error" fontSize="medium" onClick={() => onRemove(record.key)} style={{ cursor: 'pointer' }} />;
      },
    },
  ];
};

export const AdjustStockColumn = ({ onChange, onRemove, options }) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
      default:
        tag = color.disabled;
        break;
    }
    return tag;
  };
  return [
    {
      title: 'NO',
      dataIndex: 'rowNo',
      width: '45px',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '188px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'type',
        title: 'ประเภท',
        handleChange: onChange,
        dataOption: [
          { value: 'NEG', label: 'NEGATIVE' },
          { value: 'POS', label: 'POSITIVE' },
        ],
      }),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_code',
      width: '400px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'item_code',
        title: 'ชื่อสินค้า',
        handleChange: onChange,
        dataOption: options.optionItems,
      }),
    },
    {
      title: 'serial',
      dataIndex: 'serial_number',
      width: '200px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'serial_number',
        title: 'serial',
        handleChange: onChange,
      }),
    },
    {
      title: 'serial2',
      dataIndex: 'serial_number2',
      width: '200px',
      align: 'left',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'serial_number2',
        title: 'serial2',
        handleChange: onChange,
      }),
      render: (text) => {
        return <p>{text}</p>;
      },
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '150px',
      align: 'center',
      onCell: (record) => ({
        record,
        // editable: true,
        dataIndex: 'serial_status',
        title: 'Serial Status',
        handleChange: onChange,
        dataOption: options.optionSerialStatus,
      }),
      render: (text, record) => {
        return record.serial_status === null ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'ต้นทุน',
      dataIndex: 'cost',
      width: '130px',
      align: 'right',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'cost',
        title: 'ต้นทุน',
        handleChange: onChange,
      }),
      render: (text) => {
        return <p>{convertStrToFormat(text, 'money')}</p>;
      },
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '150px',
      align: 'center',
      onCell: (record) => ({
        record,
        editable: true,
        dataIndex: 'branch',
        title: 'สาขา',
        handleChange: onChange,
        dataOption: options.optionBranch,
      }),
      render: (text, record) => {
        return <p>{record.branch_name}</p>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '20px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <DeleteOutlineIcon color="error" fontSize="medium" onClick={() => onRemove(record.key)} style={{ cursor: 'pointer' }} />;
      },
    },
  ];
};

export const ReportMonitorStockNonSerialColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'สาขา',
      dataIndex: 'branch_name',
      width: '100px',
      align: 'left',
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '80px',
      align: 'left',
      ...renderSearchColumn('item_code', searchInput),
    },

    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('item_name', searchInput),
    },
    {
      title: 'จำนวน',
      dataIndex: 'stock',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.stock - b.stock,
    },
    {
      title: 'ระหว่างส่ง',
      dataIndex: 'inTransit',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.inTransit - b.inTransit,
    },
    {
      title: 'คงเหลือ',
      dataIndex: 'remaining',
      width: '50px',
      align: 'right',
      sorter: (a, b) => a.remaining - b.remaining,
    },
  ];
};

export const ReportFollowTrackColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'แอดมินผู้ติดตาม',
      dataIndex: 'actionBy',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('actionBy', searchInput),
    },
    {
      title: 'วันที่ติดตาม',
      dataIndex: 'date',
      width: '100px',
      align: 'left',
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: 'เกินกำหนด',
      dataIndex: 'aging',
      width: '80px',
      align: 'center',
    },

    {
      title: 'จำนวนลูกค้า',
      dataIndex: 'qty',
      width: '80px',
      align: 'right',
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'ยอดรวม (ค้างชำระ)',
      dataIndex: 'amount',
      width: '120px',
      align: 'right',
      render: (text) => {
        return convertStrToFormat(text, 'money');
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return (
          <ButtonTheme
            useFor="VIEW"
            title="รายละเอียด"
            onClick={() =>
              window.open(
                `/follow-track-detail-report/?action_by=${record.actionBy}&start_date=${record.date}&end_date=${record.date}&stage=${record.stageNo}`,
                '_blank',
              )
            }
          />
        );
      },
    },
  ];
};

export const ReportFollowTrackDetailColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: 'No',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'customerName',
      width: '300px',
      align: 'left',
      ...renderSearchColumn('customerName', searchInput),
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'telephone',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('telephone', searchInput),
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNo',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('contractNo', searchInput),
    },
    {
      title: 'เกินกำหนด',
      dataIndex: 'aging',
      width: '80px',
      align: 'center',
    },
    {
      title: 'ยอดรวม (ค้างชำระ)',
      dataIndex: 'amount',
      width: '100px',
      align: 'right',
      render: (text) => {
        return convertStrToFormat(text, 'money');
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'บันทึกการติดตาม',
      dataIndex: 'remark',
      width: '300px',
      align: 'left',
      ...renderSearchColumn('remark', searchInput),
    },
    {
      title: 'แอดมินผู้ติดตาม',
      dataIndex: 'actionBy',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('actionBy', searchInput),
    },
  ];
};

export const ReportAdjustStockNonserialColumn = ({ options }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'no',
      width: '40px',
      align: 'center',
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('item_code', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '350px',
      align: 'left',
      ...renderSearchColumn('item_name', searchInput),
    },

    {
      title: 'สาขา',
      dataIndex: 'branch_name',
      width: '200px',
      align: 'left',
      filters: options.branch,
      onFilter: (value, record) => record.branch.indexOf(value) === 0,
    },
    {
      title: 'ต้นทุน',
      dataIndex: 'cost',
      width: '100px',
      align: 'right',
      render: (text) => {
        return (text !== 0 && convertStrToFormat(text, 'money')) || text;
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: 'จำนวน',
      dataIndex: 'qty',
      width: '100px',
      align: 'right',
      render: (text) => {
        return convertStrToFormat(text, 'money');
      },
      sorter: (a, b) => a.qty - b.qty,
    },
    {
      title: 'วันเวลาทำรายการ',
      dataIndex: 'create_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return (text && moment(text).format('YYYY-MM-DD HH:mm')) || '';
      },
      sorter: (a, b) => moment(a.create_date).unix() - moment(b.create_date).unix(),
    },
    {
      title: 'ผู้ทำรายการ',
      dataIndex: 'userCreate',
      width: '150px',
      align: 'left',
      ...renderSearchColumn('userCreate', searchInput),
    },
  ];
};
export const TaskHistoryColumn = ({ showImage }) => {
  return [
    {
      title: 'ครั้งที่',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '10%',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend'],
    },
    {
      title: 'ผู้ติตดาม',
      dataIndex: 'trackers',
      key: 'trackers',
      width: '15%',
      render: (text, record) => {
        return text.map((e, key) => {
          if (key !== text.length - 1) {
            return <span key={key}>{e.name},</span>;
          }
          return <span key={key}>{e.name}</span>;
        });
      },
    },
    {
      title: 'สถานที่',
      dataIndex: 'location',
      key: 'location',
      width: '30%',
      render: (_, record) => {
        return record?.location?.address?.label;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (text) => {
        return convertStatusENtoTH(text, 'trackingRecord');
      },
    },
    {
      title: 'เวลาที่บันทึก',
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: '15%',
      render: (text) => {
        return convertStrToFormat(text, 'datetime');
      },
    },
    {
      title: 'คำอธิบาย',
      dataIndex: 'description',
      key: 'description',
      width: '20%',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: '15%',
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'photo',
      key: 'photo',
      width: '10%',
      align: 'center',
      render: (text) => {
        return text ? (
          <Button
            type="primary"
            icon={<Icon.search />}
            size="small"
            style={{
              background: '#000000',
            }}
            onClick={() => showImage(text)}
          />
        ) : null;
      },
    },
  ];
};

export const TrackingNoDashboardColumn = ({ openModal }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      align: 'end',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend'],
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNO',
      key: 'contractNO',
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'name',
      key: 'name',
      ...renderSearchColumn('name', searchInput),
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'job',
      key: 'job',
      width: '12%',
      align: 'center',
      render: (text, record) => {
        return text[0] == 'OTHERS' ? (
          <Tooltip title={text[1]}>
            <span>อื่นๆ : {text[1].length > 20 ? text[1].substring(0, 20) + '....' : text[1]}</span>
          </Tooltip>
        ) : (
          convertStatusENtoTH(text[0], 'jobType')
        );
      },
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'contractStatus',
      key: 'contractStatus',
      width: '12%',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return statusTagStyle(text, 'contractStatus');
      },
    },
    {
      title: 'สถานะการจัดส่ง',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '10%',
      ellipsis: true,
      render: (text, record) => {
        return statusTagStyle(text, 'sendingStatus');
      },
    },
    {
      title: 'วันที่เริ่มจัดส่ง',
      dataIndex: 'sentDate',
      key: 'sentDate',
      width: '10%',
      sorter: (a, b) => moment(a.sentDate).unix() - moment(b.sentDate).unix(),
      render: (text, record) => {
        if (!text) {
          return (
            <Row flex justify="center">
              -
            </Row>
          );
        }
        return convertStrToFormat(text, 'datetime');
      },
    },
    {
      title: 'วันที่จัดส่งสำเร็จ',
      dataIndex: 'arrivedDate',
      key: 'arrivedDate',
      width: '10%',
      ellipsis: true,
      sorter: (a, b) => moment(a.arrivedDate).unix() - moment(b.arrivedDate).unix(),
      render: (text, record) => {
        if (!text) {
          return (
            <Row flex justify="center">
              -
            </Row>
          );
        }
        return convertStrToFormat(text, 'datetime');
      },
    },
    {
      title: 'จัดส่งสำเร็จ(วัน)',
      dataIndex: 'durationAfterArrived',
      key: 'durationAfterArrived',
      width: '5%',
      align: 'end',
      sorter: (a, b) => a.durationAfterArrived - b.durationAfterArrived,
      render: (text, record) => {
        if (!text && text !== 0) {
          return (
            <Row flex justify="center">
              -
            </Row>
          );
        }
        if (
          text >= 14 &&
          (record.contractStatus === CONTRACT_STATUS.active.en || record.contractStatus === CONTRACT_STATUS.notice_of_termination.en)
        ) {
          return <text style={{ color: 'red' }}>{text}</text>;
        }
        return text;
      },
    },
    {
      title: 'ภาคสนาม',
      dataIndex: 'trackingTaskStatus',
      key: 'trackingTaskStatus',
      align: 'center',
      fixed: 'right',
      render: (value, record) => {
        if (value !== 'NORMAL' && value !== 'NOTICE_OF_TERMINATION') {
          return statusTagStyle(value, 'trackingTaskStatus');
        }

        if (
          (record.contractStatus === CONTRACT_STATUS.active.en || record.contractStatus === CONTRACT_STATUS.notice_of_termination.en) &&
          record.trackingTaskStatus === TRACKING_STATUS.notice_of_termination.en
        ) {
          if (record.status !== SENDING_STATUS.notSend.en) {
            return (
              <Button type="primary" shape="round" size="small" onClick={() => openModal('addTask', record)}>
                รอจ่ายงาน
              </Button>
            );
          } else {
            return statusTagStyle(value, 'trackingTaskStatus');
          }
        }
        return;
      },
    },
    {
      title: 'จำนวนที่จัดส่ง',
      dataIndex: 'parcelCount',
      key: 'parcelCount',
      align: 'end',
      width: '8%',
      fixed: 'right',
      sorter: (a, b) => new Date(a.parcelCount) - new Date(b.parcelCount),

      render: (text, record) => {
        return (
          <Space size="middle">
            {text}
            {text === 0 ? null : (
              <Button
                type="primary"
                icon={<Icon.search />}
                size="small"
                style={{
                  background: text !== 0 ? '#000000' : '#FFFFFF',
                }}
                disabled={text !== 0 ? false : true}
                onClick={() => openModal('details', record.contractNO)}
              />
            )}
          </Space>
        );
      },
    },
  ];
};

export const TrackingTaskColumn = ({ openModal }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      align: 'end',
      width: '80px',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend'],
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contractNO',
      key: 'contractNO',
      width: '120px',
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'contractStatus',
      key: 'contractStatus',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return statusTagStyle(text, 'contractStatus');
      },
    },
    {
      title: 'ประเภทหนังสือยกเลิกสัญญา',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '180px',
      render: (text, record) => {
        return convertStatusENtoTH(text, 'terminateContract');
      },
    },
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'trackers',
      key: 'trackers',
      width: '200px',
      render: (text, record) => {
        return text.map((e, key) => {
          if (key !== text.length - 1) {
            return <p key={key}>{e.name},</p>;
          }
          return <p key={key}>{e.name}</p>;
        });
      },
    },
    {
      title: 'รูปแบบงาน',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '200px',
      render: (text, record) => {
        return statusTagStyle(text, 'status');
      },
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'job',
      key: 'job',
      width: '12%',
      align: 'center',
      render: (text, record) => {
        return text[0] == 'OTHERS' ? (
          <Tooltip title={text[1]}>
            <span>อื่นๆ : {text[1].length > 20 ? text[1].substring(0, 20) + '....' : text[1]}</span>
          </Tooltip>
        ) : (
          convertStatusENtoTH(text[0], 'jobType')
        );
      },
    },
    {
      title: 'ค่าติดตาม',
      dataIndex: 'trackingFee',
      key: 'trackingFee',
      align: 'end',
      width: '120px',
      sorter: (a, b) => a.trackingFee - b.trackingFee,
      render: (text, record) => {
        if (text) {
          return text.toLocaleString();
        }
      },
    },
    {
      title: 'ชื่อผู้ทำสัญญา',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      ...renderSearchColumn('name', searchInput),
    },
    {
      title: 'วันที่รับคำสั่ง',
      dataIndex: 'trackingTaskCreatedDate',
      key: 'trackingTaskCreatedDate',
      width: '150px',
      sorter: (a, b) => moment(a.trackingTaskCreatedDate).unix() - moment(b.trackingTaskCreatedDate).unix(),
      render: (text, record) => {
        return convertStrToFormat(text, 'datetime');
      },
    },
    {
      title: 'ระยะเวลา(วัน)',
      dataIndex: 'durationAfterTrackingTaskCreated',
      key: 'durationAfterTrackingTaskCreated',
      align: 'right',
      width: '100px',
      sorter: (a, b) => a.durationAfterTrackingTaskCreated - b.durationAfterTrackingTaskCreated,
    },
    {
      title: 'ติดตาม(ครั้ง)',
      dataIndex: 'countTracking',
      key: 'countTracking',
      align: 'right',
      width: '100px',
      sorter: (a, b) => a.countTracking - b.countTracking,
    },
    {
      title: 'วันที่ติดตาม(ล่าสุด)',
      dataIndex: 'recentTrackingDate',
      key: 'recentTrackingDate',
      align: 'left',
      width: '200px',
      sorter: (a, b) => moment(a.recentTrackingDate).unix() - moment(b.recentTrackingDate).unix(),
      render: (text, record) => {
        if (!text) {
          return (
            <Row flex justify="center">
              -
            </Row>
          );
        }
        return convertStrToFormat(text, 'datetime');
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'trackingTaskStatus',
      key: 'trackingTaskStatus',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return statusTagStyle(text, 'trackingTaskStatus');
      },
    },
    {
      title: 'ประวัติการติดตาม',
      dataIndex: 'trackingHistory',
      key: 'trackingHistory',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return <Button onClick={() => openModal('details', record)}>รายละเอียด</Button>;
      },
    },
    {
      title: '',
      dataIndex: 'menu',
      key: 'menu',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        if (
          record?.trackingTaskStatus !== 'FINISHED' &&
          // record.trackingTaskStatus !== TRACKING_STATUS.hold.en &&
          // record.trackingTaskStatus !== TRACKING_STATUS.sue.en &&
          // record.trackingTaskStatus !== TRACKING_STATUS.baddebt.en
          !['TERMINATED', 'COMPLETED'].includes(record?.contractStatus)
        ) {
          return (
            <Space>
              <Tooltip title="แก้ไขค่าติดตาม">
                <Button
                  style={{ backgroundColor: color.edit }}
                  onClick={() => openModal('edit', record)}
                  icon={<EditOutlined style={{ fontSize: 20 }} />}
                />
              </Tooltip>
              <Tooltip title="เปลี่ยนผู้ติดตาม">
                <Button
                  style={{ backgroundColor: color.remove, color: color.white }}
                  onClick={() => openModal('change', record)}
                  icon={<SwapOutlined style={{ fontSize: 20 }} />}
                />
              </Tooltip>
            </Space>
          );
        }

        return;
      },
    },
  ];
};

export const ReportBuyStockColumn = () => {
  const searchInput = createRef();
  return [
    // {
    //   title: '#',
    //   dataIndex: 'key',
    //   key: 'key',
    //   align: 'center',
    //   width: '5%',
    //   sorter: (a, b) => a.key - b.key,
    //   sortDirections: ['descend'],
    // },
    {
      title: 'วันที่',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      align: 'center',
      width: '10%',
      sorter: (a, b) => moment(a.purchaseDate).unix() - moment(b.purchaseDate).unix(),
      render: (text, record) => {
        return convertStrToFormat(record.purchaseDate, 'dateFor');
      },
    },
    {
      title: 'เลขทีใบสั่งซื้อ',
      dataIndex: 'docNo',
      key: 'docNo',
      width: '15%',
      ...renderSearchColumn('docNo', searchInput),
    },
    {
      title: 'มูลค่าการสั่งซื้อ',
      dataIndex: 'sumCost',
      key: 'sumCost',
      align: 'right',
      width: '10%',
      render: (text, record) => {
        return convertStrToFormat(record.sumCost, 'money');
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'left',
      width: '30%',
      ...renderSearchColumn('vendor', searchInput),
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '20%',
      render: (text, record) => {
        return <ButtonTheme useFor="VIEW" title="รายละเอียด" onClick={() => window.open(`/manage-dep?docNo=${record.docNo}`, '_blank')} />;
      },
    },
  ];
};

export const ManageDEPColumn = ({
  option,
  openConfirm,
  onEditOnRow,
  onCancelEditOnRow,
  onChangeSelectedAppleStatus,
  enableEditOneRow,
  enableEditIndex,
  selected,
  onChangeSelectedMDMDate,
}) => {
  const searchInput = createRef();

  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'DEP':
      case data === 'IN_STOCK':
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'IN_TRANSFER':
      case data === 'NON_DEP':
        tag = '#0052BA';
        break;
      case data === 'TBD':
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
    }
    return tag;
  };

  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '5%',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend'],
    },
    {
      title: 'เลขทีใบสั่งซื้อ',
      dataIndex: 'docNo',
      key: 'docNo',
      width: '10%',
      ...renderSearchColumn('docNo', searchInput),
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'productName',
      key: 'productName',
      width: '25%',
      ...renderSearchColumn('productName', searchInput),
    },
    {
      title: 'S/N',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      width: '10%',
      ...renderSearchColumn('serialNumber', searchInput),
    },
    {
      title: 'Apple Status',
      dataIndex: 'appleStatus',
      key: 'appleStatus',
      align: 'center',
      width: '120px',
      filters: option.optionAppleStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.appleStatus === value;
      },
      render: (text, record) => {
        return enableEditOneRow && record.key === enableEditIndex ? (
          <Space size="middle">
            {text === 0 ? null : (
              <Select
                style={
                  record.appleStatus === 'TBD'
                    ? { width: 150, color: '#d41717' }
                    : record.appleStatus === 'DEP'
                      ? { width: 150, color: '#61c264' }
                      : { width: 150, color: '#0052BA' }
                }
                onChange={(e) => onChangeSelectedAppleStatus(e, record.key)}
                options={option.optionAppleStatus}
                value={record.appleStatus}
              />
            )}
          </Space>
        ) : (
          <Text style={record.appleStatus === 'TBD' ? { color: '#d41717' } : record.appleStatus === 'DEP' ? { color: '#61c264' } : { color: '#0052BA' }}>
            {record.appleStatus === 'NON_DEP' ? 'Non-DEP' : record.appleStatus}
          </Text>
        );
      },
    },
    {
      title: 'Serial Status',
      dataIndex: 'serialStatus',
      key: 'serialStatus',
      align: 'center',
      width: '120px',
      render: (text, record) => {
        return record.serialStatus === null || record.serialStatus === '-' ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serialStatus)}>
            {record.serialStatus === 'READY' ? 'พร้อมขาย' : record.serialStatus === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serialStatus}
          </Tag>
        );
      },
    },
    {
      title: 'วันที่ MDM',
      dataIndex: 'mdm',
      key: 'mdm',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return enableEditOneRow && record.key === enableEditIndex && record?.productName && record?.productName?.includes('(พิเศษ)') ? (
          <Space size="middle">
            {text === 0 ? (
              text
            ) : (
              <DatePicker
                placeholder={'เลือกวันที่'}
                style={{ width: '100%' }}
                value={record.mdm !== null ? moment(new Date(record.mdm), 'DD/MM/YYYY') : null}
                onChange={(e) => onChangeSelectedMDMDate(e, record.key)}
                format={'DD/MM/YYYY'}
              />
            )}
          </Space>
        ) : (
          <Text>{record.mdm !== null ? moment(record.mdm).format('DD/MM/YYYY') : '-'}</Text>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return enableEditOneRow && record.key === enableEditIndex ? (
          <div style={{ display: 'inline-block' }}>
            <Button
              style={{ backgroundColor: color.danger, color: color.white, borderRadius: 60 }}
              className="ant-btn-customize"
              icon={<CloseOutlined />}
              onClick={() => onCancelEditOnRow(record.key)}
            />
            <Button
              style={{ backgroundColor: color.submit, color: color.white, borderRadius: 60, marginLeft: 10 }}
              className="ant-btn-customize"
              icon={<CheckOutlined />}
              onClick={() => openConfirm('edit_one', record)}
            />
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            <Button
              style={
                selected.length !== 0 ? { backgroundColor: color.disabled, color: color.white } : { backgroundColor: color.edit, color: color.black }
              }
              className="ant-btn-customize"
              icon={<EditOutlined />}
              disabled={selected.length !== 0}
              onClick={() => onEditOnRow(record.key)}>
              {'แก้ไข'}
            </Button>
          </div>
        );
      },
    },
  ];
};

export const ReportStockSerialColumn = ({ options, fn }) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'DEP':
      case data === 'IN_STOCK':
      case data === 'READY':
        tag = '#61c264';
        break;
      case data === 'IN_TRANSFER':
      case data === 'NON_DEP':
        tag = '#0052BA';
        break;
      case data === 'TBD':
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
    }
    return tag;
  };
  const searchInput = createRef();
  return [
    {
      title: 'สาขาปลายทาง',
      dataIndex: 'branch',
      key: 'branch',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        let branch_name = options.optionBranch.filter((el) => el.value === record.branch);
        return branch_name[0].label;
      },
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'itemName',
      key: 'itemName',
      width: '300px',
      ...renderSearchColumn('itemName', searchInput),
    },
    {
      title: 'S/N',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      width: '150px',
      ...renderSearchColumn('serialNumber', searchInput),
    },
    {
      title: 'ประเภทสินค้า',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: '100px',
      filters: options.optionItemType.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.type === value;
      },
    },
    {
      title: 'Apple Status',
      dataIndex: 'appleStatus',
      key: 'appleStatus',
      align: 'center',
      width: '150px',
      filters: options.optionAppleStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.appleStatus === value;
      },
      render: (text, record) => {
        return record.appleStatus === null ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.appleStatus)}>{record.appleStatus === 'NON_DEP' ? 'Non-DEP' : record.appleStatus}</Tag>
        );
      },
    },
    {
      title: 'สถานะสินค้า',
      dataIndex: 'itemStatus',
      key: 'itemStatus',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        return record.itemStatus === null ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.itemStatus)}>{record.itemStatus === 'IN_TRANSFER' ? 'อยู่ระหว่างส่ง' : 'ในสต็อก'}</Tag>
        );
      },
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return record.serial_status === null ? (
          <Text>{'-'}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '150px',
      key: 'remark',
      align: 'right',
      // fixed: "right",
      render: (text, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => fn.openModal(record, 'EDIT_REMARK')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={record?.remark || ''}
          />
        );
      },
    },
  ];
};

export const ReportStockBranchColumn = (dataSumTotalAll, dataSumON_Hold, dataSumCenter, optionBranch, dataSumAll, selected) => {
  let columnOtherBranchData = [];

  optionBranch.map((l) => {
    return dataSumAll.map((s) => {
      return columnOtherBranchData.push({
        // title: l.label,
        title: () => {
          return (
            <div>
              <div style={{ alignSelf: 'center' }}>{l.label}</div>
              <hr color="white" />
            </div>
          );
        },
        children: [
          {
            title: () => {
              return (
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: -30,
                      marginBottom: 10,
                      alignSelf: 'center',
                      background: s[`${l.value}`].sumTotal > 0 ? color.success2 : s[`${l.value}`].sumTotall < 0 ? color.red : color.light_gray,
                      color: s[`${l.value}`].sumTotal > 0 ? color.white : s[`${l.value}`].sumTotal < 0 ? color.black : color.gray,
                    }}>
                    {s[`${l.value}`].sumTotal}
                  </div>
                  <div>{'จำนวน'}</div>
                </div>
              );
            },
            dataIndex: `${l.value}_total`,
            key: `${l.value}_total`,
            align: 'center',
            width: '120px',
            render: (text, record, index) => {
              let highLight = selected.findIndex((el) => el.index === index + 1);

              return {
                props: {
                  style:
                    highLight === -1
                      ? {
                        background: record[`${l.value}`].total > 0 ? color.success2 : record[`${l.value}`].total < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].total > 0 ? color.white : record[`${l.value}`].total < 0 ? color.black : color.gray,
                      }
                      : {
                        borderTop: '4px solid #213BD1',
                        borderBottom: '4px solid #213BD1',
                        background: record[`${l.value}`].total > 0 ? color.success2 : record[`${l.value}`].total < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].total > 0 ? color.white : record[`${l.value}`].total < 0 ? color.black : color.gray,
                      },
                },
                children: <div style={{ fontWeight: 'bold' }}>{record[`${l.value}`].total}</div>,
              };
            },
          },
          {
            title: () => {
              return (
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: -30,
                      marginBottom: 10,
                      alignSelf: 'center',
                      background: s[`${l.value}`].sumInTransit > 0 ? color.light_yellow : color.light_gray,
                      color: s[`${l.value}`].sumInTransit > 0 ? color.black : color.gray,
                    }}>
                    {s[`${l.value}`].sumInTransit}
                  </div>
                  <div>{'ระหว่างส่ง'}</div>
                </div>
              );
            },
            dataIndex: `${l.value}_inTransit`,
            key: `${l.value}_inTransit`,
            align: 'center',
            width: '120px',
            render: (text, record, index) => {
              let highLight = selected.findIndex((el) => el.index === index + 1);

              return {
                props: {
                  style:
                    highLight === -1
                      ? {
                        background: record[`${l.value}`].inTransit > 0 ? color.light_yellow : color.light_gray,
                        color: record[`${l.value}`].inTransit > 0 ? color.black : color.gray,
                      }
                      : {
                        borderTop: '4px solid #213BD1',
                        borderBottom: '4px solid #213BD1',
                        background: record[`${l.value}`].inTransit > 0 ? color.light_yellow : color.light_gray,
                        color: record[`${l.value}`].inTransit > 0 ? color.black : color.gray,
                      },
                },
                children: <div style={{ fontWeight: 'bold' }}>{record[`${l.value}`].inTransit}</div>,
              };
            },
          },
          {
            title: () => {
              return (
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: -30,
                      marginBottom: 10,
                      alignSelf: 'center',
                      background: s[`${l.value}`].sumReserve > 0 ? color.success2 : s[`${l.value}`].sumReserve < 0 ? color.red : color.light_gray,
                      color: s[`${l.value}`].sumReserve > 0 ? color.white : s[`${l.value}`].sumReserve < 0 ? color.black : color.gray,
                    }}>
                    {s[`${l.value}`].sumReserve}
                  </div>
                  <div>{'จอง'}</div>
                </div>
              );
            },
            dataIndex: `${l.value}_reserve`,
            key: `${l.value}_reserve`,
            align: 'center',
            width: '120px',
            render: (text, record, index) => {
              let highLight = selected.findIndex((el) => el.index === index + 1);

              return {
                props: {
                  style:
                    highLight === -1
                      ? {
                        background:
                          record[`${l.value}`].reserve > 0 ? color.success2 : record[`${l.value}`].reserve < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].reserve > 0 ? color.white : record[`${l.value}`].reserve < 0 ? color.black : color.gray,
                      }
                      : {
                        borderTop: '4px solid #213BD1',
                        borderBottom: '4px solid #213BD1',
                        background:
                          record[`${l.value}`].reserve > 0 ? color.success2 : record[`${l.value}`].reserve < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].reserve > 0 ? color.white : record[`${l.value}`].reserve < 0 ? color.black : color.gray,
                      },
                },
                children: <div style={{ fontWeight: 'bold' }}>{record[`${l.value}`].reserve}</div>,
              };
            },
          },
          {
            title: () => {
              return (
                <div>
                  <div
                    style={{
                      fontWeight: 'bold',
                      marginTop: -30,
                      marginBottom: 10,
                      alignSelf: 'center',
                      background: s[`${l.value}`].sumRemain > 0 ? color.success2 : s[`${l.value}`].sumRemain < 0 ? color.red : color.light_gray,
                      color: s[`${l.value}`].sumRemain > 0 ? color.white : s[`${l.value}`].sumRemain < 0 ? color.black : color.gray,
                    }}>
                    {s[`${l.value}`].sumRemain}
                  </div>
                  <div>{'คงเหลือ'}</div>
                </div>
              );
            },
            dataIndex: `${l.value}_remain`,
            key: `${l.value}_remain`,
            align: 'center',
            width: '120px',
            render: (text, record, index) => {
              let highLight = selected.findIndex((el) => el.index === index + 1);

              return {
                props: {
                  style:
                    highLight === -1
                      ? {
                        background:
                          record[`${l.value}`].remain > 0 ? color.success2 : record[`${l.value}`].remain < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].remain > 0 ? color.white : record[`${l.value}`].remain < 0 ? color.black : color.gray,
                        borderRight: '4px solid #737373',
                      }
                      : {
                        borderTop: '4px solid #213BD1',
                        borderBottom: '4px solid #213BD1',
                        background:
                          record[`${l.value}`].remain > 0 ? color.success2 : record[`${l.value}`].remain < 0 ? color.red : color.light_gray,
                        color: record[`${l.value}`].remain > 0 ? color.white : record[`${l.value}`].remain < 0 ? color.black : color.gray,
                        borderRight: '4px solid #737373',
                      },
                },
                children: <div style={{ fontWeight: 'bold' }}>{record[`${l.value}`].remain}</div>,
              };
            },
          },
        ],
      });
    });
  });

  return [
    {
      title: () => {
        return <div style={{ textAlign: 'center' }}>ชื่อสินค้า</div>;
      },
      dataIndex: 'name',
      key: 'name',
      width: '350px',
      fixed: 'left',
      render: (text, record, index) => {
        let highLight = selected.findIndex((el) => el.index === index + 1);

        return {
          props: {
            style:
              highLight === -1
                ? {
                  background: color.white,
                }
                : {
                  borderTop: '4px solid #213BD1',
                  borderBottom: '4px solid #213BD1',
                  background: color.white,
                },
          },
          children: <div>{record.name}</div>,
        };
      },
    },
    {
      title: () => {
        return (
          <div>
            <div style={{ marginTop: -33 }}>ทุกสาขา</div>
            <hr color="white" />
            <div
              style={{
                alignSelf: 'center',
                background: dataSumTotalAll > 0 ? color.success2 : dataSumTotalAll < 0 ? color.red : color.light_gray,
                color: dataSumTotalAll > 0 ? color.white : dataSumTotalAll < 0 ? color.black : color.gray,
              }}>
              {dataSumTotalAll}
            </div>
          </div>
        );
      },
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      width: '100px',
      fixed: 'left',
      render: (text, record, index) => {
        let highLight = selected.findIndex((el) => el.index === index + 1);

        return {
          props: {
            style:
              highLight === -1
                ? {
                  background: record.total > 0 ? color.success2 : record.total < 0 ? color.red : color.light_gray,
                  color: record.total > 0 ? color.white : record.total < 0 ? color.black : color.gray,
                }
                : {
                  borderTop: '4px solid #213BD1',
                  borderBottom: '4px solid #213BD1',
                  background: record.total > 0 ? color.success2 : record.total < 0 ? color.red : color.light_gray,
                  color: record.total > 0 ? color.white : record.total < 0 ? color.black : color.gray,
                },
          },
          children: <div style={{ fontWeight: 'bold' }}>{record.total}</div>,
        };
      },
    },
    {
      title: () => {
        return (
          <div>
            <div style={{ marginTop: -33 }}>รอขาย</div>
            <hr color="white" />
            <div
              style={{
                alignSelf: 'center',
                background: dataSumCenter.sumWaitSell > 0 ? color.success2 : dataSumCenter.sumWaitSell < 0 ? color.red : color.light_gray,
                color: dataSumCenter.sumWaitSell > 0 ? color.white : dataSumCenter.sumWaitSell < 0 ? color.black : color.gray,
              }}>
              {dataSumCenter.sumWaitSell}
            </div>
          </div>
        );
      },
      dataIndex: 'CENTER_waitSell',
      key: 'CENTER_waitSell',
      align: 'center',
      width: '130px',
      fixed: 'left',
      render: (text, record, index) => {
        let highLight = selected.findIndex((el) => el.index === index + 1);

        return {
          props: {
            style:
              highLight === -1
                ? {
                  background: record.CENTER.waitSell > 0 ? color.success2 : record.CENTER.waitSell < 0 ? color.red : color.light_gray,
                  color: record.CENTER.waitSell > 0 ? color.white : record.CENTER.waitSell < 0 ? color.black : color.gray,
                  borderRight: '4px solid #737373',
                }
                : {
                  borderTop: '4px solid #213BD1',
                  borderBottom: '4px solid #213BD1',
                  background: record.CENTER.waitSell > 0 ? color.success2 : record.CENTER.waitSell < 0 ? color.red : color.light_gray,
                  color: record.CENTER.waitSell > 0 ? color.white : record.CENTER.waitSell < 0 ? color.black : color.gray,
                  borderRight: '4px solid #737373',
                },
          },
          children: <div style={{ fontWeight: 'bold' }}>{record.CENTER.waitSell}</div>,
        };
      },
    },
    {
      title: () => {
        return (
          <div>
            <div style={{ alignSelf: 'center' }}>On Hold</div>
            <hr color="white" />
          </div>
        );
      },
      children: [
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumON_Hold.sumTotal > 0 ? color.success2 : dataSumON_Hold.sumTotal < 0 ? color.red : color.light_gray,
                    color: dataSumON_Hold.sumTotal > 0 ? color.white : dataSumON_Hold.sumTotal < 0 ? color.black : color.gray,
                  }}>
                  {dataSumON_Hold.sumTotal}
                </div>
                <div>{'จำนวน'}</div>
              </div>
            );
          },
          dataIndex: 'ON_HOLD_total',
          key: 'ON_HOLD_total',
          align: 'center',
          width: '100px',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.ON_HOLD.total > 0 ? color.success2 : record.ON_HOLD.total < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.total > 0 ? color.white : record.ON_HOLD.total < 0 ? color.black : color.gray,
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      background: record.ON_HOLD.total > 0 ? color.success2 : record.ON_HOLD.total < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.total > 0 ? color.white : record.ON_HOLD.total < 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.ON_HOLD.total}</div>,
            };
          },
        },
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumON_Hold.sumTotal > 0 ? color.success2 : dataSumON_Hold.sumTotal < 0 ? color.red : color.light_gray,
                    color: dataSumON_Hold.sumTotal > 0 ? color.white : dataSumON_Hold.sumTotal < 0 ? color.black : color.gray,
                  }}>
                  {dataSumON_Hold.sumInTransit}
                </div>
                <div>{'ระหว่างส่ง'}</div>
              </div>
            );
          },
          dataIndex: 'ON_HOLD_inTransit',
          key: 'ON_HOLD_inTransit',
          align: 'center',
          width: '120px',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.ON_HOLD.inTransit > 0 ? color.success2 : record.ON_HOLD.inTransit < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.inTransit > 0 ? color.white : record.ON_HOLD.inTransit < 0 ? color.black : color.gray,
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      background: record.ON_HOLD.inTransit > 0 ? color.success2 : record.ON_HOLD.inTransit < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.inTransit > 0 ? color.white : record.ON_HOLD.inTransit < 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.ON_HOLD.inTransit}</div>,
            };
          },
        },
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumON_Hold.sumTotal > 0 ? color.success2 : dataSumON_Hold.sumTotal < 0 ? color.red : color.light_gray,
                    color: dataSumON_Hold.sumTotal > 0 ? color.white : dataSumON_Hold.sumTotal < 0 ? color.black : color.gray,
                  }}>
                  {dataSumON_Hold.sumRemain}
                </div>
                <div>{'คงเหลือ'}</div>
              </div>
            );
          },
          dataIndex: 'ON_HOLD_remain',
          key: 'ON_HOLD_remain',
          align: 'center',
          width: '120px',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.ON_HOLD.remain > 0 ? color.success2 : record.ON_HOLD.remain < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.remain > 0 ? color.white : record.ON_HOLD.remain < 0 ? color.black : color.gray,
                      borderRight: '4px solid #737373',
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      borderRight: '4px solid #737373',
                      background: record.ON_HOLD.remain > 0 ? color.success2 : record.ON_HOLD.remain < 0 ? color.red : color.light_gray,
                      color: record.ON_HOLD.remain > 0 ? color.white : record.ON_HOLD.remain < 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.ON_HOLD.remain}</div>,
            };
          },
        },
      ],
    },
    {
      title: () => {
        return (
          <div>
            <div style={{ alignSelf: 'center' }}>CENTER: วงเวียนใหญ่</div>
            <hr color="white" />
          </div>
        );
      },
      children: [
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumCenter.sumTotal > 0 ? color.success2 : dataSumCenter.sumTotal < 0 ? color.red : color.light_gray,
                    color: dataSumCenter.sumTotal > 0 ? color.white : dataSumCenter.sumTotal < 0 ? color.black : color.gray,
                  }}>
                  {dataSumCenter.sumTotal}
                </div>
                <div>{'จำนวน'}</div>
              </div>
            );
          },
          dataIndex: 'CENTER_total',
          key: 'CENTER_total',
          align: 'center',
          width: '130px',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.CENTER.total > 0 ? color.success2 : record.CENTER.total < 0 ? color.red : color.light_gray,
                      color: record.CENTER.total > 0 ? color.white : record.CENTER.total < 0 ? color.black : color.gray,
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      background: record.CENTER.total > 0 ? color.success2 : record.CENTER.total < 0 ? color.red : color.light_gray,
                      color: record.CENTER.total > 0 ? color.white : record.CENTER.total < 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.CENTER.total}</div>,
            };
          },
        },
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumCenter.sumInTransit > 0 ? color.light_yellow : color.light_gray,
                    color: dataSumCenter.sumInTransit > 0 ? color.black : color.gray,
                  }}>
                  {dataSumCenter.sumInTransit}
                </div>
                <div>{'Return'}</div>
              </div>
            );
          },
          dataIndex: 'CENTER_inTransit',
          key: 'CENTER_inTransit',
          align: 'center',
          width: '130px',
          // fixed: 'left',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.CENTER.inTransit > 0 ? color.light_yellow : color.light_gray,
                      color: record.CENTER.inTransit > 0 ? color.black : color.gray,
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      background: record.CENTER.inTransit > 0 ? color.light_yellow : color.light_gray,
                      color: record.CENTER.inTransit > 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.CENTER.inTransit}</div>,
            };
          },
        },
        {
          title: () => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: 'bold',
                    marginTop: -30,
                    marginBottom: 10,
                    alignSelf: 'center',
                    background: dataSumCenter.sumReserve > 0 ? color.success2 : dataSumCenter.sumReserve < 0 ? color.red : color.light_gray,
                    color: dataSumCenter.sumReserve > 0 ? color.white : dataSumCenter.sumReserve < 0 ? color.black : color.gray,
                  }}>
                  {dataSumCenter.sumReserve}
                </div>
                <div>{'จอง'}</div>
              </div>
            );
          },
          dataIndex: 'CENTER_reserve',
          key: 'CENTER_reserve',
          align: 'center',
          width: '130px',
          // fixed: 'left',
          render: (text, record, index) => {
            let highLight = selected.findIndex((el) => el.index === index + 1);

            return {
              props: {
                style:
                  highLight === -1
                    ? {
                      background: record.CENTER.reserve > 0 ? color.success2 : record.CENTER.reserve < 0 ? color.red : color.light_gray,
                      color: record.CENTER.reserve > 0 ? color.white : record.CENTER.reserve < 0 ? color.black : color.gray,
                      borderRight: '4px solid #737373',
                    }
                    : {
                      borderTop: '4px solid #213BD1',
                      borderBottom: '4px solid #213BD1',
                      borderRight: '4px solid #737373',
                      background: record.CENTER.reserve > 0 ? color.success2 : record.CENTER.reserve < 0 ? color.red : color.light_gray,
                      color: record.CENTER.reserve > 0 ? color.white : record.CENTER.reserve < 0 ? color.black : color.gray,
                    },
              },
              children: <div style={{ fontWeight: 'bold' }}>{record.CENTER.reserve}</div>,
            };
          },
        },
      ],
    },
    ...columnOtherBranchData,
  ];
};

export const ReportCountStockColumn = ({ option }) => {
  const searchInput = createRef();
  return [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: '5%',
      sorter: (a, b) => a.key - b.key,
      sortDirections: ['descend'],
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      key: 'branch',
      align: 'center',
      width: '10%',
      filters: option.optionBranch.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => {
        return record.branch === value;
      },
      render: (text, record) => {
        return record.branch === 'ON_HOLD' ? 'On Hold' : record.branch;
      },
    },
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'documentNo',
      key: 'documentNo',
      width: '15%',
      ...renderSearchColumn('documentNo', searchInput),
    },
    {
      title: 'ผู้สร้างเอกสาร',
      dataIndex: 'userCreate',
      key: 'userCreate',
      align: 'center',
      width: '20%',
      ...renderSearchColumn('userCreate', searchInput),
    },
    {
      title: 'วันที่สร้าง',
      dataIndex: 'createDate',
      key: 'createDate',
      align: 'center',
      width: '10%',
      sorter: (a, b) => moment(a.createDate).unix() - moment(b.createDate).unix(),
      render: (text) => {
        return (text && moment.utc(text).utcOffset(0, true).format('YYYY-MM-DD HH:mm')) || '';
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status_count_stock',
      width: '10%',
      align: 'center',
      filters: [
        { value: 'CHECK', text: 'CHECK' },
        { value: 'COMFIRM', text: 'COMFIRM' },
        { value: 'CANCEL', text: 'CANCEL' },
      ],
      onFilter: (value, record) => record.status_count_stock.indexOf(value) === 0,
      render: (text, record) => {
        return !record.status_count_stock ? (
          <Text>{''}</Text>
        ) : (
          <Tag color={record.status_count_stock === 'CHECK' ? color.edit : record.status_count_stock === 'CONFIRM' ? color.success : color.danger}>
            {record.status_count_stock === 'CHECK'
              ? 'ตรวจสอบ'
              : record.status_count_stock === 'CONFIRM'
                ? 'ยืนยัน'
                : record.status_count_stock === 'CANCEL'
                  ? 'ยกเลิก'
                  : ''}
          </Tag>
        );
      },
    },
  ];
};

export const ContractListColumn = ({ option }) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'ACTIVE':
        tag = color.on_process;
        break;
      case data === 'WAIT_SIGNATURE':
        tag = color.edit;
        break;
      case data === 'TERMINATED':
        tag = color.disabled;
        break;
      case data === 'COMPLETED':
        tag = color.success;
        break;
      case data === 'WAIT_ADMIN_CONFIRM':
        tag = color.manage;
        break;
      case data === 'WAIT_FINANCE_APPROVE':
        tag = color.tranfer;
        break;
      case data === 'IMPOUNDED':
        tag = color.danger;
        break;
    }
    return tag;
  };
  // const searchInput = createRef();
  return [
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      key: 'contract_no',
      width: '10%',
      align: 'center',
      // ...renderSearchColumn('contract_no', searchInput),
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
      // ...renderSearchColumn('fullname', searchInput),
      render: (text, record) => {
        return record.user.general.fullname;
      },
    },
    {
      title: 'เกรด',
      dataIndex: 'grade',
      key: 'grade',
      width: '5%',
      align: 'center',
    },
    {
      title: 'วันที่ทำสัญญา',
      dataIndex: 'transaction_date',
      key: 'transaction_date',
      width: '10%',
      align: 'center',
      // ...renderSearchColumn('transaction_date', searchInput),
    },
    {
      title: 'ผู้ทำสัญญา',
      dataIndex: 'admin_created',
      key: 'admin_created',
      width: '10%',
      align: 'center',
      // ...renderSearchColumn('transaction_date', searchInput),
      render: (text, record) => {
        return record?.admin_created?.general?.fullname || '';
      },
    },
    {
      title: 'ประเภทสัญญา',
      dataIndex: 'type',
      key: 'type',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return record.type === 'REFINANCE'
          ? 'ไอโฟนแลกเงิน'
          : record.type === 'INSTALLMENT'
            ? 'ผ่อนดาวน์'
            : record.type === 'REFINPACKAGE'
              ? 'ไอโฟนแลกเงิน + แพ็คเกจ'
              : '';
      },
    },
    {
      title: 'รูปแบบสัญญา',
      dataIndex: 'contract_type',
      key: 'contract_type',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return record.contract_type === 'E_CONTRACT' ? 'E-Contract' : 'Paper';
      },
    },
    {
      title: 'สาขา',
      dataIndex: 'branchName',
      key: 'branch',
      align: 'center',
      width: '15%',
    },
    {
      title: 'สถานะสัญญา',
      dataIndex: 'statusName',
      key: 'statusName',
      align: 'center',
      width: '15%',
      render: (text, record) => {
        return <Tag color={tagColor(record.status)}>{record.statusName}</Tag>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '5%',
      fixed: 'right',
      render: (text, record) => {
        return (
          <ButtonTheme
            useFor="VIEW"
            style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
            bgcolor="white"
            title=""
            onClick={() => window.open(`/contract/${record.contract_no}`, '_blank')}
          />
        );
      },
    },
  ];
};

export const CustomerListColumn = ({ onCreateContract, openModal, onAddOtherInfo, onChangeSelectContract }) => {
  // const searchInput = createRef();
  return [
    {
      title: 'เลขบัตรปชช',
      dataIndex: 'code',
      key: 'code',
      width: '200px',
      align: 'left',
      // ...renderSearchColumn('contract_no', searchInput),
    },
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '250px',
      // ...renderSearchColumn('fullname', searchInput),
      render: (text, record) => {
        return record.general.fullname;
      },
    },
    {
      title: 'เกรด',
      dataIndex: 'grade_after',
      key: 'grade_after',
      width: '100px',
      align: 'center',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      key: 'tel',
      width: '10%',
      align: 'center',
      // ...renderSearchColumn('transaction_date', searchInput),
    },
    {
      title: 'OTP',
      dataIndex: 'otp',
      key: 'otp',
      width: '150px',
      align: 'center',
    },
    {
      // title: 'สัญญา',
      title: () => {
        return <div style={{ marginLeft: 10, textAlign: 'left' }}>สัญญา</div>;
      },
      dataIndex: 'contract',
      key: 'contract',
      align: 'left',
      width: '250px',
      render: (text, record) => {
        let data = record.contract;
        let optionContract = data.map((a) => {
          return { value: a.contract_no, label: a.contract_no };
        });

        return data.length !== 0 ? (
          <div style={{ display: 'flex' }}>
            <Select
              // showSearch={true}
              value={record.recentContract}
              style={{ width: 200 }}
              placeholder={'เลือกเลขที่สัญญา'}
              onChange={(e) => onChangeSelectContract(e, record.index)}
              options={optionContract}
            />
            <ButtonTheme
              useFor="VIEW"
              style={{ color: '#a1a1a1', borderColor: '#d9d9d9' }}
              bgcolor="white"
              title=""
              onClick={() => window.open(`/contract/${record.recentContract}`, '_blank')}
            />
          </div>
        ) : (
          <></>
        );
      },
    },
    {
      title: 'หมายเหตุล่าสุด',
      dataIndex: 'remark',
      width: '300px',
      key: 'remark',
      align: 'right',
      // fixed:"right",
      render: (text, record) => {
        let remarkLog = record?.remark || [];

        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, remarkLog, 'remark')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={record?.recentRemark || ''}
          />
        );
      },
    },
    {
      title: 'social id (chatcone)',
      dataIndex: 'social_id',
      width: '150px',
      key: 'social_id',
      align: 'center',
      render: (text, record) => {
        const social_id = record?.chatcone?.social_id;
        return (
          <>
            {social_id ? <Tag color={color.success}>มี</Tag> : <Tag color={color.clear}>ไม่มี</Tag>}
            <EditOutlined
              style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }}
              onClick={() => openModal(record, '', 'EDIT_SOCIAL_ID')}
            />
          </>
        );
      },
    },
    {
      title: 'เมนู',
      dataIndex: 'action',
      align: 'right',
      width: '350px',
      fixed: 'right',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonTheme
              useFor="CUSTOM"
              style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
              bgcolor="white"
              icon={<EditOutlined />}
              title=""
              onClick={() => openModal(record, '', 'edit_customer')}
            />
            {record.type === 'USER_DOWN_PAYMENT' ? (
              <></>
            ) : (
              <ButtonTheme
                useFor="CUSTOM"
                style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
                bgcolor="white"
                title="สร้างสัญญา"
                onClick={() => onCreateContract(record)}
              />
            )}
            <ButtonTheme useFor="CREATE_INFO" bgColor="#66CCB3" title="เพิ่มข้อมูลอื่นๆ" onClick={() => onAddOtherInfo(record)} />
          </div>
        );
      },
    },
  ];
};

export const TrackPaymentListColumn = () => {
  // const searchInput = createRef();

  const getTrackStatus = (status) => {
    switch (status) {
      case 'PENDING':
        return 'รอการติดตาม';
      case 'TRACKED':
        return 'ติดตามแล้ว';
      case 'NO_OVERDUED':
        return 'ไม่มีงวดค้าง';
      default:
        return 'ผิดพลาด';
    }
  };

  const getStageRange = (stage) => {
    switch (stage) {
      case 5:
        return '60+';
      case 4:
        return '46-60';
      case 3:
        return '31-45';
      case 2:
        return '15-30';
      case 1:
        return '1-14';
      default:
        return '-';
    }
  };
  return [
    {
      title: 'ชื่อ-นามสกุล',
      dataIndex: 'fullname',
      key: 'fullname',
      width: '15%',
      render: (text, record) => {
        return record.user.general.fullname;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'code',
      width: '10%',
      align: 'center',
      filters: [
        { value: 'PENDING', text: 'รอการติดตาม' },
        { value: 'TRACKED', text: 'ติดตามแล้ว' },
        { value: 'NO_OVERDUED', text: 'ไม่มีงวดค้าง' },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return getTrackStatus(record.status);
      },
    },
    {
      title: 'ยอดชำระ',
      dataIndex: 'amount',
      key: 'amount',
      width: '7%',
      align: 'center',
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        return convertStrToFormat(record.amount, 'money');
      },
    },
    {
      title: 'เกินกำหนด(วัน)',
      dataIndex: 'stage',
      key: 'stage',
      width: '5%',
      align: 'center',
      sorter: (a, b) => a.stage - b.stage,
      filters: [
        // { value: '0', text: '-' },
        { value: '1', text: '1-14' },
        { value: '2', text: '15-30' },
        { value: '3', text: '31-45' },
        { value: '4', text: '46-60' },
        { value: '5', text: '60+' },
      ],
      onFilter: (value, record) => String(record.stage).indexOf(value) === 0,
      render: (text, record) => {
        return getStageRange(record.stage);
      },
    },
    {
      title: 'ติดตาม(ครั้ง)',
      dataIndex: 'action_count',
      key: 'action_count',
      width: '5%',
      colSpan: 2,
      align: 'center',
      sorter: (a, b) => moment(a.action_count).unix() - moment(b.action_count).unix(),
    },
    {
      title: 'ติดตามล่าสุด',
      dataIndex: 'action_date',
      key: 'action_date',
      width: '10%',
      colSpan: 0,
      align: 'center',
      render: (text, record) => {
        return !!record.action_date ? moment(record.action_date).format('DD/MM/YYYY') : null;
      },
    },
    {
      title: 'ติดตามครั้งต่อไป',
      dataIndex: 'next_action_date',
      key: 'next_action_date',
      width: '10%',
      align: 'center',
      sorter: (a, b) => moment(a.next_action_date).unix() - moment(b.next_action_date).unix(),
      render: (text, record) => {
        return !!record.next_action_date ? moment(record.next_action_date).format('DD/MM/YYYY') : null;
      },
    },
    {
      title: 'ช่องทางล่าสุด',
      dataIndex: 'channel',
      key: 'channel',
      width: '7%',
      align: 'center',
      filters: [
        { value: 'CALL', text: 'CALL' },
        { value: 'CHAT', text: 'CHAT' },
        { value: 'MDM', text: 'MDM' },
        { value: 'ICLOUD', text: 'ICLOUD' },
        { value: 'SMS', text: 'SMS' },
        { value: 'APP', text: 'APP' },
      ],
      onFilter: (value, record) => record.channel.indexOf(value) === 0,
    },
    {
      title: 'หมายเหตุล่าสุด',
      dataIndex: 'remark',
      key: 'remark',
      width: '15%',
      align: 'left',
    },
    {
      title: 'เมนู',
      dataIndex: 'action',
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (text, record) => {
        return (
          <ButtonTheme
            useFor="CUSTOM"
            style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
            bgcolor="white"
            title="รายละเอียด"
            onClick={() => window.open(`/track/${record._id}`, '_blank')}
          />
        );
      },
    },
  ];
};

export const MiradoreConfigListColumn = () => {
  // const searchInput = createRef();
  return [
    {
      title: '',
      dataIndex: 'row',
      key: 'row',
      width: '3%',
      align: 'center',
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      align: 'left',
      // ...renderSearchColumn('contract_no', searchInput),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '25%',
      align: 'left',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      width: '25%',
      align: 'left',
      // ...renderSearchColumn('transaction_date', searchInput),
    },
    {
      title: 'Configuration Type',
      dataIndex: 'config_type',
      key: 'config_type',
      width: '25%',
      align: 'left',
      render: (text, record) => {
        return record.config_type.toLowerCase().includes('iOS'.toLowerCase())
          ? record.config_type.replace('iOS', '')
          : record.config_type.toLowerCase().includes('Android'.toLowerCase())
            ? record.config_type.replace('Android', '')
            : record.config_type;
      },
    },
  ];
};

export const ReportTrackingFeeColumn = ({ data, option }) => {
  const searchInput = createRef();

  return [
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'trackers',
      key: 'trackers',
      width: '15%',
      ...renderSearchColumn('trackers', searchInput),
      render: (text, record, index) => {
        if (index !== data.lastRecord) {
          if (record?.trackers === 'รวม') {
            return {
              children: 'รวม',
              props: {
                colSpan: 3,
                align: 'center',
              },
            };
          } else {
            return record.trackers;
          }
        } else if (index === data.lastRecord) {
          return {
            children: 'รวม',
            props: {
              colSpan: 3,
              align: 'center',
            },
          };
        }
      },
    },
    {
      title: 'ชื่อ-สกุล (ลูกค้า)',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...renderSearchColumn('name', searchInput),
      render: (text, record, index) => {
        if (index !== data.lastRecord) {
          if (record?.name === '') {
            return {
              children: '',
              props: {
                colSpan: 0,
                align: 'center',
              },
            };
          } else {
            return record.name;
          }
        } else {
          return {
            children: record.name,
            props: {
              colSpan: 0,
            },
          };
        }
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      key: 'contract_no',
      width: '10%',
      align: 'center',
      render: (text, record, index) => {
        if (index !== data.lastRecord) {
          if (record?.contract_no === '') {
            return {
              children: '',
              props: {
                colSpan: 0,
                align: 'center',
              },
            };
          } else {
            return record.contract_no;
          }
        } else {
          return {
            children: record.contract_no,
            props: {
              colSpan: 0,
            },
          };
        }
      },
    },
    {
      title: 'วันที่จ่าย(ล่าสุด)',
      dataIndex: 'paymentRecentDate',
      key: 'paymentRecentDate',
      align: 'center',
      sorter: (a, b) => moment(a.paymentRecentDate).unix() - moment(b.paymentRecentDate).unix(),
      render: (text, record) => {
        return record.paymentRecentDate.includes('T') ? moment(record.paymentRecentDate).format('YYYY-MM-DD HH:mm') : record.paymentRecentDate;
      },
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => {
        return convertStrToFormat(record.amount.toString(), 'money');
      },
    },
    {
      title: 'จ่ายแล้ว',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      align: 'right',
      render: (text, record) => {
        return convertStrToFormat(record.paid_amount.toString(), 'money');
      },
    },
    {
      title: 'ค้างจ่าย',
      dataIndex: 'remain_amount',
      key: 'remain_amount',
      align: 'right',
      render: (text, record) => {
        return convertStrToFormat(record.remain_amount.toString(), 'money');
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      filters: option.optionStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return record.status === '' ? (
          <Text>{''}</Text>
        ) : (
          <Tag color={record.status === 'COMPLETED' ? 'green' : 'red'}>{record.status === 'COMPLETED' ? 'จ่ายแล้ว' : 'ค้างจ่าย'}</Tag>
        );
      },
    },
  ];
};

export const ReportTrackingDailyColumn = () => {
  const searchInput = createRef();

  return [
    {
      title: 'วันที่ติดตาม',
      dataIndex: 'trackingDate',
      key: 'trackingDate',
      align: 'center',
      width: '150px',
      sorter: (a, b) => moment(a.trackingDate).unix() - moment(b.trackingDate).unix(),
    },
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'trackers',
      key: 'trackers',
      width: '200px',
      align: 'left',
      ...renderSearchColumn('trackers', searchInput),
    },
    {
      title: 'Check-In',
      dataIndex: 'checkin',
      key: 'checkin',
      width: '300px',
      align: 'left',
      render: (_text, record) => {
        return (
          <a
            href={`https://maps.google.com/maps?daddr=${record?.checkin?.location?.position?.lat},${record?.checkin?.location?.position?.lon}`}
            target="_blank"
            rel="noreferrer">
            {record?.checkin?.location?.name ? record?.checkin?.location?.name : (record?.checkin?.location?.address?.district ? record?.checkin?.location?.address?.district : '')}
          </a>
        );
      },
    },
    {
      title: 'Time-In',
      dataIndex: 'checkin',
      key: 'checkin',
      width: '100px',
      align: 'right',
      render: (_text, record) => {
        return record?.checkin?.time;
      },
    },
    {
      title: 'ปิดสัญญา',
      dataIndex: 'close',
      key: 'close',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.close - b.close,
    },
    {
      title: 'คืนเครือง',
      dataIndex: 'return',
      key: 'return',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.return - b.return,
    },
    {
      title: 'นัดหมาย',
      dataIndex: 'appointment',
      key: 'appointment',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.appointment - b.appointment,
    },
    {
      title: 'ไม่สามารถติดตามได้',
      dataIndex: 'not_tracked',
      key: 'not_tracked',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.not_tracked - b.not_tracked,
    },
    {
      title: 'ไม่พบลูกค้า',
      dataIndex: 'not_found',
      key: 'not_found',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.not_found - b.not_found,
    },
    {
      title: 'ปิดเคส',
      dataIndex: 'finished',
      key: 'finished',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.finished - b.finished,
    },
    {
      title: 'อื่นๆ',
      dataIndex: 'other',
      key: 'other',
      width: '120px',
      align: 'right',
      sorter: (a, b) => a.other - b.other,
    },
    {
      title: 'รวมจำนวนเคสที่ติดตามแล้ว',
      dataIndex: 'total',
      key: 'total',
      width: '150px',
      align: 'right',
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Check-Out',
      dataIndex: 'checkout',
      key: 'checkout',
      width: '300px',
      align: 'left',
      render: (_text, record) => {
        return (
          <a
            href={`https://maps.google.com/maps?daddr=${record?.checkout?.location?.position?.lat},${record?.checkout?.location?.position?.lon}`}
            target="_blank"
            rel="noreferrer">
            {record?.checkout?.location?.name ? record?.checkout?.location?.name : (record?.checkout?.location?.address?.district ? record?.checkout?.location?.address?.district : '')}
          </a>
        );
      },
    },
    {
      title: 'Time-Out',
      dataIndex: 'checkout',
      key: 'checkout',
      width: '100px',
      align: 'right',
      render: (_text, record) => {
        return record?.checkout?.time;
      },
    },
    {
      title: 'ระยะทางรวม',
      dataIndex: 'totalDistance',
      key: 'totalDistance',
      width: '100px',
      align: 'right',
      render: (_text, record) => {
        return record?.totalDistance ? record?.totalDistance : '0';
      },
    },
    {
      title: 'ระยะทางกลับบ้าน',
      dataIndex: 'distanceHome',
      key: 'distanceHome',
      width: '100px',
      align: 'right',
      render: (_text, record) => {
        return record?.distanceHome ? record?.distanceHome : '0';
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '100px',
      fixed: 'right',
      render: (text, record) => {
        return (
          <ButtonTheme
            useFor="VIEW"
            title="รายละเอียด"
            onClick={() =>
              window.open(
                `/report-tracking-agent?startDate=${moment(record?.trackingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')}&IDtrackers=${record?.IDtrackers
                }`,
                '_blank',
              )
            }
          />
        );
      },
    },
  ];
};

export const ReportTrackingAgentColumn = ({ option, showImage, openModal }) => {
  const searchInput = createRef();

  return [
    {
      title: 'ชื่อผู้ติดตาม',
      dataIndex: 'trackers',
      key: 'trackers',
      width: '15%',
      align: 'left',
      ...renderSearchColumn('trackers', searchInput),
    },
    {
      title: 'ครั้งที่',
      dataIndex: 'index',
      key: 'index',
      width: '5%',
      align: 'center',
    },
    {
      title: 'วันที่บันทึก',
      dataIndex: 'created_date',
      key: 'created_date',
      align: 'center',
      width: '8%',
      // sorter: (a, b) => moment(a.trackingDate).unix() - moment(b.trackingDate).unix(),
      render: (text, record) => {
        return record.created_date && moment(record.created_date).format('DD/MM/YYYY');
      },
    },
    {
      title: 'เวลาที่บันทึก',
      dataIndex: 'trackingTime',
      key: 'trackingTime',
      align: 'center',
      width: '5%',
      // sorter: (a, b) => moment(a.created_date).unix() - moment(b.created_date).unix(),
      render: (text, record) => {
        return record.created_date && moment(record.created_date).format('HH:mm');
      },
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      key: 'contract_no',
      width: '10%',
      align: 'center',
      ...renderSearchColumn('contract_no', searchInput),
    },
    {
      title: 'รูปแบบงาน',
      dataIndex: 'tocStatus',
      key: 'tocStatus',
      align: 'center',
      width: '200px',
      render: (text, record) => {
        return statusTagStyle(text, 'status');
      },
    },
    {
      title: 'ประเภทงาน',
      dataIndex: 'job',
      key: 'job',
      width: '12%',
      align: 'center',
      render: (text, record) => {
        return text[0] == 'OTHERS' ? (
          <Tooltip title={text[1]}>
            <span>อื่นๆ : {text[1].length > 20 ? text[1].substring(0, 20) + '....' : text[1]}</span>
          </Tooltip>
        ) : (
          convertStatusENtoTH(text[0], 'jobType')
        );
      },
    },
    {
      title: 'ชื่อ-สกุล (ลูกค้า)',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      ...renderSearchColumn('name', searchInput),
    },
    {
      title: 'สถานที่',
      dataIndex: 'location',
      key: 'location',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        return (
          <Button
            type="link"
            onClick={() => window.open(`https://maps.google.com/maps?daddr=${record?.location.lat},${record?.location.lon}`, '_blank', 'noreferrer')}>
            {record?.location.name}
          </Button>
        );
      },
    },
    {
      title: 'อำเภอ (เขต)',
      dataIndex: 'district',
      key: 'district',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        return record?.location?.address?.district || '';
      },
    },
    {
      title: 'จังหวัด',
      dataIndex: 'county',
      key: 'county',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        return record?.location?.address?.county || '';
      },
    },
    {
      title: 'ระยะทาง (กม.)',
      dataIndex: 'distance',
      key: 'distance',
      width: '8%',
      align: 'center',
      render: (text, record) => {
        return record.distanceKm;
      },
    },
    {
      title: 'ระยะทางกลับบ้าน (กม.)',
      dataIndex: 'distanceHome',
      key: 'distanceHome',
      width: '8%',
      align: 'center',
      render: (text, record) => {
        return record?.distanceHomeKm;
      },
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '8%',
      filters: option.optionTrackingRecordStatus.map((el) => {
        return {
          value: el.value,
          text: el.label,
        };
      }),
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return statusTagStyle(text, 'trackingRecordStatus');
      },
    },
    {
      title: 'รูปภาพ',
      dataIndex: 'image',
      key: 'image',
      width: '3%',
      align: 'center',
      render: (text, record) => {
        return record?.photo !== null ? (
          <ButtonTheme
            useFor="VIEW"
            style={{ background: '#000000', color: 'white', borderColor: '#A1A1A1' }}
            bgcolor="white"
            title=""
            onClick={() => showImage(record?.photo.location)}
          />
        ) : (
          <></>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (text, record) => {
        return <ButtonTheme useFor="VIEW" title="รายละเอียด" onClick={() => openModal(record, 'detail')} />;
      },
    },
  ];
};

export const InterestRateRefinanceTableColumn = () => {
  const searchInput = createRef();

  return [
    {
      title: 'วันที่เริ่มใช้งาน',
      dataIndex: 'start_date',
      key: 'start_date',
      align: 'center',
      width: '8%',
    },
    {
      title: 'วันที่สิ้นสุดใช้งาน',
      dataIndex: 'end_date',
      key: 'end_date',
      align: 'center',
      width: '8%',
    },
    {
      title: 'ช่วงราคาที่กำหนด',
      dataIndex: 'price_range',
      key: 'price_range',
      align: 'center',
      width: '15%',
    },
    {
      title: 'อัตราดอกเบี่ย',
      dataIndex: 'interest',
      key: 'interest',
      align: 'center',
      width: '15%',
    },
    {
      title: 'จำนวนงวด',
      dataIndex: 'periods',
      key: 'periods',
      align: 'center',
      width: '10%',
    },
  ];
};

export const DownPaymentColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: 'วันที่ชำระ',
      dataIndex: 'date',
      width: '200px',
      align: 'center',
    },
    {
      title: 'เลขที่หนังสือออมดาวน์',
      dataIndex: 'downpayment_no',
      width: '200px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '200px',
      align: 'left',
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'user_id_card',
      width: '200px',
      align: 'center',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '180px',
      align: 'center',
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'total_amount',
      width: '200px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.total_amount, 'money');
      },
    },
    {
      title: 'ชื่อผู้ทำรายการ',
      dataIndex: 'pay_from_qr_name',
      width: '180px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '60px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag
            color={
              record.status === 'WAIT_PAYMENT'
                ? color.load
                : record.status === 'ACTIVE'
                  ? color.on_process
                  : record.status === 'WAIT_PROMISE'
                    ? color.edit
                    : record.status === 'NEW_PROMISE'
                      ? color.success
                      : record.status === 'WAIT_FINANCE_APPROVE'
                        ? color.tranfer
                        : record.status === 'CANCEL'
                          ? color.danger
                          : color.disabled
            }>
            {text === 'WAIT_PAYMENT'
              ? 'รอชำระเงิน'
              : text === 'ACTIVE'
                ? 'กำลังใช้งาน'
                : text === 'WAIT_PROMISE'
                  ? 'รอทำสัญญา'
                  : text === 'NEW_PROMISE'
                    ? 'ทำสัญญาเสร็จสิ้น'
                    : text === 'WAIT_FINANCE_APPROVE'
                      ? 'รอการเงินอนุมัติ'
                      : text === 'CANCEL'
                        ? 'ยกเลิก'
                        : text}
          </Tag>
        );
      },
    },
  ];
};

export const ReportDownPaymentDailyColumn = () => {
  const searchInput = createRef();
  return [
    {
      title: 'วันที่ชำระ',
      dataIndex: 'date',
      width: '100px',
      align: 'center',
    },
    {
      title: 'ยอดเงิน',
      dataIndex: 'total_amount',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.total_amount, 'money');
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '50px',
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              style={{ color: '#4B4B4B', borderColor: '#A1A1A1' }}
              bgcolor="white"
              useFor="VIEW"
              title="รายละเอียด"
              onClick={() =>
                window.open(
                  `/report-down-payment?start_date=${encodeURIComponent(record.transaction_date)}&end_date=${encodeURIComponent(
                    record.transaction_date,
                  )}`,
                  '_blank',
                )
              }
            />
          </>
        );
      },
    },
  ];
};

export const DownPaymentReportColumn = ({ openModal, setIsEdit, isEdit, perAction }) => {
  const classes = useStyles();
  const [updateStatus, setUpdateStatus] = useState('DEFAULT');
  
  const handleEdit = (record) => {
    setIsEdit(record.key);
    setUpdateStatus('DEFAULT')
  };

  const handleSave = (record) => {
    const update = updateStatus === 'DEFAULT' ? record.status : updateStatus
    openModal(record, 'UPDATE_STATUS', '', update)
  };

  const handleCancel = () => {
    setIsEdit(null);
  };

  const changeStatus = (e) => {
    setUpdateStatus(e);
  };

  return [
    {
      title: 'วันที่ลงทะเบียน',
      dataIndex: 'created_date',
      width: '140px',
      align: 'center',
      render: (text, record) => {
        return record?.created_date ? moment(record?.created_date).format('DD/MM/YYYY HH:mm') : '';
      },
    },
    {
      title: 'เลขที่หนังสือออมดาวน์',
      dataIndex: 'down_payment_id_book',
      width: '140px',
      align: 'center',
    },
    {
      title: 'เลขบัตรประชาชน',
      dataIndex: 'user_id_card',
      width: '110px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '120px',
      align: 'left',
    },
    {
      title: 'เบอร์โทรศัพท์',
      dataIndex: 'tel',
      width: '120px',
      align: 'center',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '180px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <>
            {isEdit !== record.key ? (<Tag
              color={
                record.status === 'WAIT_PAYMENT'
                  ? color.manage
                  : record.status === 'ACTIVE'
                    ? color.on_process
                    : record.status === 'WAIT_PROMISE'
                      ? color.edit
                      : record.status === 'NEW_PROMISE'
                        ? color.success
                        : record.status === 'WAIT_FINANCE_APPROVE'
                          ? color.tranfer
                          : record.status === 'CANCEL'
                            ? color.danger
                            : color.disabled
              }>
              {text === 'WAIT_PAYMENT'
                ? 'รอชำระเงิน'
                : text === 'ACTIVE'
                  ? 'กำลังใช้งาน'
                  : text === 'WAIT_PROMISE'
                    ? 'รอทำสัญญา'
                    : text === 'NEW_PROMISE'
                      ? 'ทำสัญญาเสร็จสิ้น'
                      : text === 'WAIT_FINANCE_APPROVE'
                        ? 'รอการเงินอนุมัติ'
                        : text === 'CANCEL'
                          ? 'ยกเลิก'
                          : text}

            </Tag>) : (
              <Select
                className={classes.select}
                labelId={`transaction-status-label-${record.key}`}
                id={`transaction-status-${record.key}`}
                value={updateStatus === 'DEFAULT' ? record.status : updateStatus}
                style={{ width: '70%' }}
                onChange={changeStatus}>
                <MenuItem value="ACTIVE">กำลังใช้งาน</MenuItem>
                <MenuItem value="WAIT_PROMISE">รอทำสัญญา</MenuItem>
              </Select>
            )}

            {(perAction.includes('edit') && isEdit !== record.key) && (record.status === 'ACTIVE' || (record.status === 'WAIT_PROMISE' && record.remain_amount > 0)) && (
              <EditOutlined
                onClick={() => handleEdit(record)}
                style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} >
              </EditOutlined>
            )}
            {isEdit === record.key && (
              <>
                <CheckCircle
                  onClick={() => handleSave(record)}
                  className={`save ${classes.icon_btn}`}
                />
                <Cancel
                  onClick={() => handleCancel(record)}
                  className={`cancel ${classes.icon_btn}`}
                />
              </>
            )}
          </>
        );
      },
    },
    {
      title: 'จ่ายแล้ว',
      dataIndex: 'paid_amount',
      width: '70px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.paid_amount.toString(), 'money');
      },
    },
    {
      title: 'คงเหลือ',
      dataIndex: 'remain_amount',
      width: '60px',
      align: 'center',
      render: (text, record) => {
        return convertStrToFormat(record.remain_amount.toString(), 'money');
      },
    },
    {
      title: 'Facebook',
      dataIndex: 'user',
      width: '120px',
      align: 'center',
      render: (data, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'FACEBOOK')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={data?.social_media ? ('facebook' in data.social_media ? data.social_media.facebook : '') : ''}
          />
        );
      },
    },
    {
      title: 'Line ID',
      dataIndex: 'user',
      width: '120px',
      align: 'center',
      render: (data, record) => {
        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'LINEID')}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={data?.social_media ? ('line' in data.social_media ? data.social_media.line : '') : ''}
          />
        );
      },
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '120px',
      align: 'right',
      render: (text, record) => {
        let remarkLog = 'log_remark' in record ? record.log_remark : [];

        return (
          <TextSmall
            cursor={'pointer'}
            onClick={() => openModal(record, 'REMARK', remarkLog)}
            icon={<EditOutlined style={{ borderRadius: '50%', backgroundColor: '#ffc107', padding: '6px' }} />}
            text={text || ''}
          />
        );
      },
    },
    {
      title: 'จัดการ',
      dataIndex: 'action',
      width: '100px',
      align: 'left',
      fixed: 'right',
      render: (text, record) => {
        let remarkLog = 'log_remark' in record ? record.log_remark : [];
        return (
          <>
            <Space>
              <Tooltip placement="topLeft" title={'รายละเอียด'} arrowPointAtCenter>
                <Button
                  style={{ backgroundColor: color.success, color: color.white }}
                  className="ant-btn-customize"
                  icon={<SearchOutlined />}
                  onClick={() => openModal(record, 'VIEW')}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title={'Transection'} arrowPointAtCenter>
                <Button
                  style={{ backgroundColor: color.on_process, color: color.white }}
                  className="ant-btn-customize"
                  icon={<FileDoneOutlined />}
                  onClick={() =>
                    window.open(`/report-down-payment?user_id_card=${record.user_id_card}&tel=${record.tel}&name=${record.name}`, '_blank')
                  }
                />
              </Tooltip>
              {['WAIT_PAYMENT', 'ACTIVE', 'WAIT_PROMISE'].includes(record?.status) && (
                <>
                  <Tooltip placement="topLeft" title={'ยกเลิกออมดาวน์'} arrowPointAtCenter>
                    <Button
                      style={{ backgroundColor: color.danger, color: color.white }}
                      className="ant-btn-customize"
                      icon={<CloseCircleOutlined />}
                      onClick={() => openModal(record, 'CANCEL')}
                    />
                  </Tooltip>
                </>
              )}
              {record?.product_edit == true && (
                <>
                  <Tooltip placement="topLeft" title={'แก้ไขสินค้า'} arrowPointAtCenter>
                    <Button
                      style={{ backgroundColor: color.edit, color: color.black }}
                      className="ant-btn-customize"
                      icon={<EditOutlined />}
                      onClick={() => openModal(record, 'EDIT')}
                    />
                  </Tooltip>
                </>
              )}
            </Space>
          </>
        );
      },
    },
  ];
};

export const DownPaymentFinanceColumn = ({ openModal }) => {
  return [
    {
      title: '#',
      dataIndex: 'rowNo',
      width: '40px',
      align: 'center',
    },
    {
      title: 'เลขที่หนังสือออมดาวน์',
      dataIndex: 'down_payment_id_book',
      width: '130px',
      align: 'left',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '130px',
      align: 'left',
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      width: '60px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text, record) => {
        return (
          <Tag color={record.status === 'WAIT_FINANCE_APPROVE' ? color.tranfer : color.disabled}>
            {text === 'WAIT_FINANCE_APPROVE' ? 'รอการเงินอนุมัติ' : text}
          </Tag>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              useFor="VERIFY"
              style={{ color: 'black', borderRadius: '5px', width: '130px' }}
              title="ตรวจสอบ"
              onClick={() => openModal(record, 'verify')}
            />
          </>
        );
      },
    },
  ];
};

export const IcloudTrackingStatusColumn = () => {
  return [
    {
      title: '#',
      dataIndex: 'row_no',
      width: '30px',
      align: 'center',
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '130px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '140px',
      align: 'left',
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'tel',
      width: '130px',
      align: 'center',
    },
    {
      title: 'วันที่ทำสัญญา',
      dataIndex: 'date',
      width: '130px',
      align: 'center',
    },
    {
      title: 'Job type',
      dataIndex: 'job_type',
      width: '220px',
      align: 'center',
    },
    {
      title: 'เกินกำหนด (วัน)',
      dataIndex: 'overdue',
      width: '80px',
      align: 'center',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      width: '220px',
      align: 'center',
    },
    {
      title: 'สถานะ icloud',
      dataIndex: 'icloud_status',
      width: '120px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text) => {
        return (
          <Tag
            color={
              text === 'Turn off find my'
                ? color.on_process
                : text === 'Online'
                  ? color.load
                  : text === 'Offline'
                    ? color.danger
                    : text === 'Change password'
                      ? color.edit
                      : text === 'Apple id inactive'
                        ? color.clear
                        : color.disabled
            }>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'วันที่ Check Icloud',
      dataIndex: 'created_date',
      width: '160px',
      align: 'center',
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'updated_date',
      width: '160px',
      align: 'center',
    },
    {
      title: 'สถานะการประมวลผล',
      dataIndex: 'queue_service_status',
      width: '150px',
      align: 'center',
      render: (text) => {
        return text === 'Processing' ? <Tag color={color.on_process}>{text}</Tag> : '-';
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '20px',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              useFor="CUSTOM"
              style={{ color: 'black', width: '20px', boxShadow: '1px 1px 2px black' }}
              icon={<Icon.search />}
              bgcolor="white"
              size="small"
              onClick={() => window.open(`/contract/${record.contract_no}`, '_blank')}
            />
          </>
        );
      },
    },
  ];
};

export const IcloudTrackingStatusLogColumn = () => {
  return [
    {
      title: '#',
      dataIndex: 'row_no',
      width: '30px',
      align: 'center',
    },
    {
      title: 'เลขที่สัญญา',
      dataIndex: 'contract_no',
      width: '130px',
      align: 'center',
    },
    {
      title: 'ชื่อ - นามสกุล',
      dataIndex: 'name',
      width: '140px',
      align: 'left',
    },
    {
      title: 'เบอร์โทร',
      dataIndex: 'tel',
      width: '130px',
      align: 'center',
    },
    {
      title: 'วันที่ทำสัญญา',
      dataIndex: 'date',
      width: '130px',
      align: 'center',
    },
    {
      title: 'Job type',
      dataIndex: 'job_type',
      width: '200px',
      align: 'center',
    },
    {
      title: 'เกินกำหนด (วัน)',
      dataIndex: 'overdue',
      width: '80px',
      align: 'center',
    },
    {
      title: 'อีเมล',
      dataIndex: 'email',
      width: '220px',
      align: 'center',
    },
    {
      title: 'สถานะ icloud',
      dataIndex: 'icloud_status',
      width: '120px',
      align: 'center',
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      render: (text) => {
        return (
          <Tag
            color={
              text === 'Turn off find my'
                ? color.on_process
                : text === 'Online'
                  ? color.load
                  : text === 'Offline'
                    ? color.danger
                    : text === 'Change password'
                      ? color.edit
                      : text === 'Apple id inactive'
                        ? color.clear
                        : color.disabled
            }>
            {text}
          </Tag>
        );
      },
    },
    {
      title: 'ตำบล',
      dataIndex: 'subdistrict',
      width: '160px',
      align: 'center',
    },
    {
      title: 'จังหวัด',
      dataIndex: 'province',
      width: '160px',
      align: 'center',
    },
    {
      title: 'วันที่ Check Icloud',
      dataIndex: 'created_date',
      width: '160px',
      align: 'center',
    },
    {
      title: 'อัพเดทล่าสุด',
      dataIndex: 'updated_date',
      width: '160px',
      align: 'center',
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '20px',
      render: (text, record) => {
        return (
          <>
            <ButtonTheme
              useFor="CUSTOM"
              style={{ color: 'black', width: '20px', boxShadow: '1px 1px 2px black' }}
              icon={<Icon.search />}
              bgcolor="white"
              size="small"
              onClick={() => window.open(`/contract/${record.contract_no}`, '_blank')}
            />
          </>
        );
      },
    },
  ];
};

export const ManageSpecialProductColumn = ({ options, data, fn }) => {
  const tagColor = (data) => {
    let tag = '';
    switch (!!data) {
      case data === 'READY':
      case data === 'ในสต็อก':
        tag = '#61c264';
        break;
      case data === 'อยู่ระหว่างส่ง':
        tag = '#0052BA';
        break;
      case data === 'NOT_READY':
        tag = '#d41717';
        break;
      default:
        tag = color.disabled;
        break;
    }
    return tag;
  };
  return [
    {
      title: 'เลขที่ใบสั่งซื้อ',
      dataIndex: 'docNo',
      width: '120px',
      align: 'center',
    },
    {
      title: 'สาขา',
      dataIndex: 'product_branch',
      width: '120px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product',
      width: '260px',
      align: 'left',
    },
    {
      title: 'S/N',
      dataIndex: 'serial_number',
      width: '120px',
      align: 'center',
    },
    {
      title: 'Serial Status',
      dataIndex: 'serial_status',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return data.enableEditOneRow && record.key === data.enableEditIndex ? (
          <Space size="middle">
            {text === 0 ? null : (
              <Select
                // style={record.appleStatus === 'TBD' ? { width: 150, color: '#d41717' } : record.appleStatus === 'DEP' ? { width: 150, color: '#61c264' } : { width: 150, color: '#0052BA' }}
                onChange={(e) => fn.onChangeSelectedSerialStatus(e, record.key)}
                options={options.optionSerialStatus}
                value={record.serial_status}
              />
            )}
          </Space>
        ) : record.serial_status === '-' ? (
          <Text>{record.serial_status}</Text>
        ) : (
          <Tag color={tagColor(record.serial_status)}>
            {record.serial_status === 'READY' ? 'พร้อมขาย' : record.serial_status === 'NOT_READY' ? 'ไม่พร้อมขาย' : record.serial_status}
          </Tag>
        );
      },
    },
    {
      title: 'Sale grade',
      dataIndex: 'sale_grade',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return data.enableEditOneRow && record.key === data.enableEditIndex ? (
          <Space size="middle">
            {text === 0 ? null : (
              <Select
                // style={record.appleStatus === 'TBD' ? { width: 150, color: '#d41717' } : record.appleStatus === 'DEP' ? { width: 150, color: '#61c264' } : { width: 150, color: '#0052BA' }}
                onChange={(e) => fn.onChangeSelectedSaleGrade(e, record.key)}
                options={options.optionSaleGrade}
                value={record.sale_grade}
              />
            )}
          </Space>
        ) : (
          <Text>{record.sale_grade}</Text>
        );
      },
    },
    {
      title: 'วันที่ MDM',
      dataIndex: 'date',
      width: '120px',
      align: 'center',
    },
    {
      title: 'Apple Status',
      dataIndex: 'apple_status',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return (
          <Text style={record.apple_status === 'TBD' ? { color: '#d41717' } : record.apple_status === 'DEP' ? { color: '#61c264' } : { color: '#0052BA' }}>
            {record.apple_status === 'NON_DEP' ? 'Non-DEP' : record.apple_status}
          </Text>
        );
      },
    },
    {
      title: 'สถานะสต็อก',
      dataIndex: 'itemStatus',
      width: '120px',
      align: 'center',
      render: (text, record) => {
        return record.itemStatus ? <Tag color={tagColor(record.itemStatus)}>{record.itemStatus}</Tag> : <Text>{'-'}</Text>;
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'center',
      width: '180px',
      fixed: 'right',
      render: (text, record) => {
        return data.enableEditOneRow && record.key === data.enableEditIndex ? (
          <div style={{ display: 'inline-block' }}>
            <Button
              style={{ backgroundColor: color.danger, color: color.white, borderRadius: 60 }}
              className="ant-btn-customize"
              icon={<CloseOutlined />}
              onClick={() => fn.onCancelEditOnRow(record.key)}
            />
            <Button
              style={{ backgroundColor: color.submit, color: color.white, borderRadius: 60, marginLeft: 10 }}
              className="ant-btn-customize"
              icon={<CheckOutlined />}
              onClick={() => fn.openConfirm('edit_one', record)}
            />
          </div>
        ) : (
          <div>
            {record.date !== '' && (
              <Button
                style={
                  data.selected.length !== 0
                    ? { backgroundColor: color.disabled, color: color.white }
                    : { backgroundColor: color.edit, color: color.black }
                }
                className="ant-btn-customize"
                icon={<EditOutlined />}
                disabled={data.selected.length !== 0}
                onClick={() => fn.onEditOnRow(record.key)}>
                {'แก้ไข'}
              </Button>
            )}
          </div>
        );
      },
    },
  ];
};

export const EditSecondHandPriceColumn = ({ optionCondition, data, fn }) => {
  // let columnConditionDynamic = []

  // optionCondition.map((l) => {
  //   columnConditionDynamic.push(
  //     {
  //       title: `Condition ${l.value}`,
  //       dataIndex: `Condition ${l.value}`,
  //       key: `condition_${l.value}`,
  //       align: 'center',
  //       width: '120px',
  //       render: (text, record) => {
  //         // return record[`${l.value}`]
  //       },
  //     },
  //   )
  // })

  return [
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'product_name',
      width: '250px',
      align: 'left',
    },
    {
      title: 'Condition 1',
      dataIndex: 'condition_1',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return data.enableEdit ? (
          <div>
            <Input
              style={{ borderRadius: 5, textAlign: 'center' }}
              value={record.product_condition_1}
              onChange={(e) => fn.handleChange(e, record, 'condition_1')}
            />
          </div>
        ) : (
          <Text>{convertStrToFormat(record.product_condition_1, 'money')}</Text>
        );
      },
    },
    {
      title: 'Condition 2',
      dataIndex: 'condition_2',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return data.enableEdit ? (
          <div>
            <Input
              style={{ borderRadius: 5, textAlign: 'center' }}
              value={record.product_condition_2}
              onChange={(e) => fn.handleChange(e, record, 'condition_2')}
            />
          </div>
        ) : (
          <Text>{convertStrToFormat(record.product_condition_2, 'money')}</Text>
        );
      },
    },
    {
      title: 'Condition 3',
      dataIndex: 'condition_3',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return data.enableEdit ? (
          <div>
            <Input
              style={{ borderRadius: 5, textAlign: 'center' }}
              value={record.product_condition_3}
              onChange={(e) => fn.handleChange(e, record, 'condition_3')}
            />
          </div>
        ) : (
          <Text>{convertStrToFormat(record.product_condition_3, 'money')}</Text>
        );
      },
    },
    {
      title: 'Condition 4',
      dataIndex: 'condition_4',
      width: '150px',
      align: 'center',
      render: (text, record) => {
        return data.enableEdit ? (
          <div>
            <Input
              style={{ borderRadius: 5, textAlign: 'center' }}
              value={record.product_condition_4}
              onChange={(e) => fn.handleChange(e, record, 'condition_4')}
            />
          </div>
        ) : (
          <Text>{convertStrToFormat(record.product_condition_4, 'money')}</Text>
        );
      },
    },
    // ...columnConditionDynamic,
  ];
};

export const MovementReportColumn = ({ options }) => {
  return [
    {
      title: 'เลขที่เอกสาร',
      dataIndex: 'document_no',
      width: '200px',
      align: 'center',
    },
    {
      title: 'ผู้ทำรายการ',
      dataIndex: 'userCreate',
      width: '150px',
      align: 'left',
    },
    {
      title: 'วันที่ทำรายการ',
      dataIndex: 'create_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return (text && moment(text).format('DD/MM/YYYY HH:mm')) || '';
      },
    },
    {
      title: 'ประเภท',
      dataIndex: 'type',
      width: '100px',
      align: 'center',
    },
    {
      title: 'qty(+/-)',
      dataIndex: 'qty',
      width: '50px',
      align: 'center',
    },
    {
      title: 'สาขา',
      dataIndex: 'branch',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        let branch_name = options.optionBranch.filter((el) => el.value === record.branch);
        return branch_name[0]?.label || '';
      },
    },
    {
      title: 'วันที่รับ',
      dataIndex: 'received_date',
      width: '150px',
      align: 'left',
      render: (text) => {
        return (text && moment(text).format('DD/MM/YYYY HH:mm')) || '';
      },
    },
    {
      title: 'รหัสสินค้า',
      dataIndex: 'item_code',
      width: '150px',
      align: 'center',
    },
    {
      title: 'ชื่อสินค้า',
      dataIndex: 'item_name',
      width: '350px',
      align: 'left',
    },
    {
      title: 'Serial Number',
      dataIndex: 'serial_number',
      width: '200px',
      align: 'center',
    },
    {
      title: 'หมายเหตุ',
      dataIndex: 'remark',
      width: '180px',
      align: 'right',
    },
    {
      title: 'เกรด',
      dataIndex: 'sale_grade',
      width: '80px',
      align: 'center',
    },
  ];
};

export const StatementDownPaymentDetailLogColumn = () => {
  return [
    {
      title: 'วันที่แก้ไข',
      dataIndex: 'create_date',
      key: 'create_date',
      render: (text, record) => {
        return <span>{`${record.create_date ? moment(record.create_date).format('DD/MM/YYYY') : ''}`}</span>;
      },
    },
    {
      title: 'ทำรายการโดย',
      dataIndex: 'user_create',
      key: 'user_create',
      render: (text, record) => {
        return <span>{record.user_create ? record.user_create.name : ''}</span>;
      },
    },
    {
      title: 'รุ่น',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'ความจุ',
      dataIndex: 'storage',
      key: 'storage',
    },
    {
      title: 'สี',
      dataIndex: 'color',
      key: 'color',
    },
    {
      title: 'คอมโบ',
      dataIndex: 'combo',
      key: 'combo',
      render: (text, record) => {
        let combo = ''
        record?.combo?.length > 0 && record.combo.map((el) => {
          return combo += `${el?.name} `
        })
        return combo
      }
    },
    {
      title: 'ราคาดาวน์',
      dataIndex: 'amount',
      key: 'amount',
    },
  ];
};
